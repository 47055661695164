import { TrashIcon } from "@heroicons/react/outline"
import { PlusIcon } from "@heroicons/react/solid"
import dynamic from "next/dynamic"
import { useRouter } from "next/router"
import { useState } from "react"
import "react-quill/dist/quill.snow.css"
import { toast } from "react-toastify"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { assignmentQuestionState, statsState } from "recoil/statsState"
import api from "services/api"
import classNames from "utils/classNames"
const QuillNoSSRWrapper = dynamic(import("react-quill"), {
	ssr: false,
	loading: () => <p className="p-4">Loading ...</p>,
})

const AssignmentForm = ({ edit, setEdit }) => {
	const setStats = useSetRecoilState(statsState)
	const assignmentQuestion = useRecoilValue(assignmentQuestionState)
	let [formVisible, setFormVisible] = useState(!!edit)
	let [question, setQuestion] = useState(edit || "")
	let [loading, setLoading] = useState(false)
	const { query: params } = useRouter()
	const modules = {
		toolbar: [
			[{ size: [] }],
			["link", "image", "video"],
			["bold", "italic", "underline", "strike"],
			[{ list: "ordered" }, { list: "bullet" }],
			["clean"],
		],
		clipboard: {
			// toggle to add extra line breaks when pasting HTML:
			matchVisual: false,
		},
	}
	/*
	 * Quill editor formats
	 * See https://quilljs.com/docs/formats/
	 */
	const formats = [
		"header",
		"font",
		"size",
		"bold",
		"italic",
		"underline",
		"strike",
		"list",
		"bullet",
		"link",
		"image",
		"video",
	]

	function closeForm() {
		edit && setEdit(null)
		setQuestion()
		setFormVisible(false)
	}

	function openForm() {
		setFormVisible(true)
	}

	const handleAddAssignment = () => {
		if (edit !== question) {
			setLoading(true)
			api.post(`/v2/lms/assignment/${params.class}`, {
				question,
			})
				.then(() => {
					setLoading(false)
					setStats(prev => ({
						...prev,
						assignmentQuestion: question,
					}))
					toast.success(
						`Assignment ${edit ? "edited" : "added"} successfully`
					)
					setEdit(null)
					closeForm()
				})
				.catch(e => {
					setLoading(false)
					console.error(e)
					toast.error(
						`Error ${edit ? "editing" : "adding"} Assignment`
					)
				})
		} else {
			closeForm()
		}
	}

	const handleDeleteAssignment = () => {
		assignmentQuestion?.trim().length &&
			api
				.post(`/v2/lms/assignment/${params.class}`, {
					question: null,
				})
				.then(() => {
					setStats(prev => ({
						...prev,
						assignmentQuestion: null,
					}))
					setEdit(null)
					toast.warning("Assignment deleted")
					closeForm()
				})
				.catch(() => {
					console.error(e)
					toast.error("Error deleting Assignment")
				})
	}

	return (
		<>
			{!formVisible ? (
				<div className="my-4">
					<button
						type="button"
						onClick={openForm}
						className="flex h-16 w-full items-center justify-center space-x-2 rounded-md border border-dashed border-gray-500 bg-gray-300 bg-opacity-20 px-4 py-2 hover:bg-opacity-30 focus:outline-none"
					>
						<PlusIcon className="h-6 w-6 text-slate-500" />
						<span className="text-lg font-medium text-slate-700">
							Add Assignment
						</span>
					</button>
				</div>
			) : (
				<div
					className={classNames(
						"w-full transform rounded-2xl bg-white text-left align-middle transition-all",
						edit ? "" : ""
					)}
				>
					<form className="relative">
						<div className="w-full rounded-md border border-gray-300">
							<QuillNoSSRWrapper
								className="quill"
								theme="snow"
								modules={modules}
								formats={formats}
								placeholder="Add Assignment Question"
								defaultValue={question}
								onChange={value => setQuestion(value)}
							/>
						</div>
					</form>
					<div
						className={classNames(
							"flex flex-shrink-0 pt-4",
							assignmentQuestion
								? "justify-between"
								: "justify-end"
						)}
					>
						{assignmentQuestion && (
							<button
								type="button"
								className="inline-flex h-full items-center space-x-2 rounded border border-gray-300 bg-white px-2.5 py-2 text-sm font-medium leading-5 text-red-500 shadow-sm hover:bg-gray-50"
								onClick={handleDeleteAssignment}
							>
								<TrashIcon className="h-5 w-5" />
								<span>Delete</span>
							</button>
						)}
						<div className="flex space-x-4">
							<button
								type="button"
								className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-0"
								onClick={closeForm}
							>
								Cancel
							</button>
							<button
								type="submit"
								className="inline-flex justify-center rounded-md border border-transparent bg-black py-2 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-0"
								onClick={handleAddAssignment}
							>
								{loading
									? `${edit ? "Updat" : "Post"}ing`
									: `${edit ? "Update" : "Post"} Assignment`}
							</button>
						</div>
					</div>
				</div>
			)}
		</>
	)
}

export default AssignmentForm
