import { ClassCard } from "components"
import { differenceInCalendarDays, format } from "date-fns"
import { useEffect, useState } from "react"
import api from "services/api"
import formatDate from "utils/formatDate"

const ClassList = ({ selectedFilters, dateRange }) => {
	const [classes, setClasses] = useState([])
	const fetchClasses = () => {
		api.get(
			`/v2/trainers/classes?type=${selectedFilters.join(
				","
			)}&date=${dateRange[0].toISOString()}&page=1&limit=${
				dateRange.length === 1
					? 3
					: Math.abs(
							differenceInCalendarDays(dateRange[0], dateRange[1])
					  ) + 1
			}`
		)
			.then(classResp => {
				classResp = classResp.data.results.data

				Object.keys(classResp).forEach(key => {
					if (classResp[key].length)
						classResp[key] = classResp[key].map(p => ({
							...p,
							classStatus: p.isLive
								? "Live"
								: p.hasEnded
								? "Finished"
								: new Date() <= new Date(p.classDate)
								? "Upcoming"
								: "Pending",
							statusColor: p.isLive
								? "bg-red-100 text-red-800"
								: p.hasEnded
								? "bg-green-100 text-green-800"
								: new Date() <= new Date(p.classDate)
								? "bg-gray-100 text-gray-800"
								: "bg-gray-100 text-gray-800",
							classDate: {
								date: formatDate(p.classDate).date,
								time: formatDate(p.classDate).time,
								dateTime: p.classDate,
							},
						}))
					else delete classResp[key]
				})
				setClasses(classResp)
			})
			.catch(() => {
				setClasses([])
			})
	}

	useEffect(() => {
		fetchClasses()
	}, [selectedFilters, dateRange])

	return (
		<>
			{/* <pre>{JSON.stringify(dateRange, null, 4)}</pre> */}
			{Object.entries(classes).length ? (
				Object.entries(classes).map(([key, value]) => (
					<div
						className="flex flex-col"
						key={key}
					>
						<div className="flex flex-wrap space-x-2 text-base font-medium text-gray-900">
							<span>{format(new Date(key), "MMM dd")}</span>
						</div>
						<ol className="mt-2 divide-y divide-gray-200 text-sm leading-6 text-gray-500 transition-all">
							{value?.length ? (
								value?.map(session => (
									<li
										key={session._id}
										className="py-4"
									>
										<ClassCard session={session} />
									</li>
								))
							) : (
								<div className="flex justify-center">
									<img
										src="https://lucdn.letsupgrade.net/nodata_462ac17b3c.gif"
										className="square w-1/3"
										title="No Class Scheduled"
									/>
								</div>
							)}
						</ol>
					</div>
				))
			) : (
				<div className="flex flex-col">
					<div className="flex flex-wrap items-center space-x-2 text-base font-medium text-gray-900">
						<span>{format(dateRange[0], "MMM dd")}</span>
						{dateRange.length === 2 ? (
							<>
								<span>to</span>
								<span>{format(dateRange[1], "MMM dd")}</span>
							</>
						) : null}
					</div>
					<ol className="mt-2 divide-y divide-gray-200 text-sm leading-6 text-gray-500 transition-all">
						<div className="flex justify-center">
							<img
								src="https://lucdn.letsupgrade.net/nodata_462ac17b3c.gif"
								className="square w-1/3"
								title="No Class Scheduled"
							/>
						</div>
					</ol>
				</div>
			)}
		</>
	)
}

export default ClassList
