import { PaperAirplaneIcon } from "@heroicons/react/outline"
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/solid"
import { nanoid } from "nanoid"
import { useRouter } from "next/router"
import React, { useRef, useState } from "react"
import { defaultStyles, FileIcon } from "react-file-icon"
import { useSetRecoilState } from "recoil"
import { statsState } from "recoil/statsState"
import api from "services/api"
import classNames from "utils/classNames"
import urlify from "utils/urlify"

const Input = () => {
	const setStats = useSetRecoilState(statsState)
	const [input, setInput] = useState("")
	const [resource, setResource] = useState("")
	// const { token, classCode, pasteBinRoomId, setValue } = useAppContext();
	// const { sendResource } = usePasteBinEmitter();
	const inputRef = useRef()
	const [file, setFile] = useState("")
	const [fileData, setFileData] = useState(false)
	const [fileSelected, setFileSelected] = useState(false)
	const [fileUploading, setFileUploading] = useState(false)
	const [fileUploaded, setFileUploaded] = useState(false)
	const [fileTooLarge, setFileTooLarge] = useState(false)
	const { query: params } = useRouter()

	const getFileSizeConverted = _size => {
		let fSExt = ["Bytes", "KB", "MB", "GB"],
			i = 0
		while (_size > 900) {
			_size /= 1024
			i++
		}
		const size = Math.round(_size * 100) / 100
		return { i, size, fileSize: size + " " + fSExt[i] }
	}

	const handlePress = e => {
		e.target.style.height = "inherit"

		// Get the computed styles for the element
		var computed = window.getComputedStyle(e.target)

		// Calculate the height
		var height =
			parseInt(computed.getPropertyValue("border-top-width"), 10) +
			e.target.scrollHeight +
			parseInt(computed.getPropertyValue("border-bottom-width"), 10)

		if (height < 100) e.target.style.height = height + "px"
		else e.target.style.height = "150px"
		if (e.key === "Enter" && !e.shiftKey) {
			e.preventDefault()
			sendMessage()
		}
	}

	const handleSendResource = resource => {
		// console.debug({ ...resource, createdAt: new Date().toISOString() })
		api.post(
			`/v2/admin/classes/pastebin/resource/${params.class}`,
			resource
		)
			.then(resp => {
				setStats(prev => ({
					...prev,
					resourceList: {
						...prev.resourceList,
						total: prev.resourceList.total + 1,
						resources: [
							{
								...resource,
								createdAt: new Date().toISOString(),
							},
							...prev.resourceList.resources,
						],
					},
				}))
			})
			.catch(err => {
				console.error(err)
			})
	}

	const sendMessage = e => {
		e.preventDefault()
		if (input.trim() === "") return
		const urlified = urlify(input)
		handleSendResource({
			roomId: params.class,
			tempId: nanoid(16),
			link: urlified.urls,
			source: "input",
			ext: null,
			text: urlified.text,
			name: null,
			isImage: null,
			isVisible: true,
		})
		setInput("")
	}

	const sendFile = e => {
		e.preventDefault()
		const urlified = urlify(input)
		handleSendResource({
			...resource,
			text: urlified.text,
			isVisible: true,
		})
		setFileSelected(false)
		setFileUploading(false)
		setFileUploaded(false)
		setFileData({})
		setFile("")
		setInput("")
	}

	const handleFileChange = e => {
		const file = e.target.files[0]
		const _size = file.size
		const { i, size, fileSize } = getFileSizeConverted(_size)
		// console.debug({ i, size, fileSize })
		if (i >= 2 ? (size >= 10 ? true : false) : false) {
			setFileTooLarge(true)
			setFileUploading(false)
			setFileUploaded(false)
			setFileSelected(false)
			setFile("")
			setFileData(null)
			return
		}

		setFileTooLarge(false)
		setFile("")
		setFileSelected(true)
		setFileUploading(true)
		setFileUploaded(false)
		setFileData({
			name: file.name,
			ext: file.name.split(".").at(-1),
			isImage: file.type.split("/")[0] === "image",
			size: fileSize,
		})
		const formData = new FormData()
		formData.append("image", file)
		api.post(`/v1/pastebin/${params.class}`, formData, {
			headers: {
				"Content-Type": "multipart/form-data",
			},
		})
			.then(resp => {
				setFileUploading(false)
				setFileUploaded(true)
				setResource({
					tempId: nanoid(16),
					roomId: params.class,
					link: resp.data?.results.url,
					source: "file",
					text: "",
					name: file.name,
					size: fileSize,
					ext: file.name.split(".").at(-1),
					isImage: file.type.split("/")[0] === "image",
				})
				setFile("")
			})
			.catch(err => {
				console.error(err)
				setFileUploading(false)
				setFileUploaded(false)
				setFile("")
			})
	}

	return (
		<>
			{fileSelected && (
				<div className="flex flex-col rounded-t-md bg-gray-100 px-7 py-3">
					<div className="flex justify-between">
						<div className="flex items-center space-x-2">
							<div className="flex h-10 content-center justify-center">
								<FileIcon
									extension={fileData.ext}
									{...defaultStyles[fileData.ext]}
								/>
							</div>
							<div className="flex min-w-0 flex-1 flex-col px-4 md:grid md:gap-1">
								<span className="w-full self-center truncate break-words text-sm font-medium text-gray-900">
									{fileData.name}
								</span>
								<span className="flex space-x-2">
									{fileData.size && (
										<span className="flex-shrink-0 whitespace-nowrap text-xs text-gray-400">
											{fileData.size}
										</span>
									)}
								</span>
							</div>
						</div>

						{fileUploading && (
							<div className="flex items-center justify-center">
								<div
									style={{
										borderTopColor: "transparent",
									}}
									className="h-6 w-6 animate-spin rounded-full border-2 border-solid border-gray-900"
								></div>
							</div>
						)}
						{fileUploaded && (
							<div className="flex justify-between space-x-3">
								<div className="flex justify-center">
									<button
										className="rounded-md bg-green-500 px-4 py-2 font-bold text-white hover:bg-green-700"
										onClick={sendFile}
									>
										<CheckCircleIcon className="h-4 w-4" />
									</button>
								</div>
								<div className="flex justify-center">
									<button
										className="rounded-md bg-red-500 px-4 py-2 font-bold text-white hover:bg-red-700"
										onClick={() => {
											setFileSelected(false)
											setFileUploading(false)
											setFileUploaded(false)
											setFileData({})
										}}
									>
										<XCircleIcon className="h-4 w-4" />
									</button>
								</div>
							</div>
						)}
					</div>
				</div>
			)}
			<div className="relative mb-3 flex">
				{fileTooLarge && (
					<div className="absolute inset-x-0 -top-6 z-50 flex justify-center border-b-[1px] border-gray-300 bg-red-100">
						<span className="p-1 text-xs text-red-500">
							Your file is too large. Please upload file below
							10MB
						</span>
					</div>
				)}
				<div className="relative flex-grow">
					<form onSubmit={sendMessage}>
						<textarea
							type="text"
							ref={inputRef}
							value={input}
							onChange={e => setInput(e.target.value)}
							// onKeyPress={handlePress}
							onKeyUp={handlePress}
							onKeyDown={handlePress}
							rows={1}
							placeholder="Enter Resource"
							className={classNames(
								"block min-h-12 w-full resize-none border-gray-300 py-3 pl-4 pr-24 leading-5 shadow-sm focus:border-lu-500 focus:ring-lu-500 sm:text-sm",
								fileSelected ? "rounded-b-md" : "rounded-md"
							)}
						/>
						<button
							type="submit"
							className="absolute inset-y-0 right-10 inline-flex items-center border border-l-0 border-transparent px-2 text-sm text-gray-500"
						>
							<PaperAirplaneIcon className="h-6 w-6 rotate-90" />
						</button>
						<div className="absolute inset-y-0 right-0">
							<button className="rounded-r-max inline-flex h-full items-center border border-l-0 border-transparent px-2 text-sm text-gray-500">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									className="h-6 w-6"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth={2}
										d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13"
									/>
								</svg>
							</button>
							<input
								className="absolute inset-0 block w-full cursor-pointer px-4 py-2 opacity-0"
								type="file"
								value={file}
								onChange={handleFileChange}
							/>
						</div>
					</form>
				</div>
			</div>
		</>
	)
}

export default Input
