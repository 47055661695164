import Head from "next/head"

const HomeSeo = () => {
	return (
		<Head>
			{/* <!-- Primary Meta Tags --> */}
			<title>TrainerPreneur Program</title>
			<meta
				name="title"
				content="TrainerPreneur Program"
			/>
			<meta
				name="description"
				content="Earn up to INR 60,000 a month teaching tech skills online with LetsUpgrade programs. Join 250+ working professionals."
			/>

			{/* <!-- Open Graph / Facebook --> */}
			<meta
				property="og:type"
				content="website"
			/>
			<meta
				property="og:url"
				content="https://trainer.letsupgrade.in/"
			/>
			<meta
				property="og:title"
				content="TrainerPreneur Program"
			/>
			<meta
				property="og:description"
				content="Earn up to INR 60,000 a month teaching tech skills online with LetsUpgrade programs. Join 250+ working professionals."
			/>
			<meta
				property="og:image"
				content="https://lucdn.letsupgrade.net/Trainerpreneur_1_793cf58531.jpg"
			/>

			{/* <!-- Twitter --> */}
			<meta
				property="twitter:card"
				content="summary_large_image"
			/>
			<meta
				property="twitter:url"
				content="https://trainer.letsupgrade.in/"
			/>
			<meta
				property="twitter:title"
				content="TrainerPreneur Program"
			/>
			<meta
				property="twitter:description"
				content="Earn up to INR 60,000 a month teaching tech skills online with LetsUpgrade programs. Join 250+ working professionals."
			/>
			<meta
				property="twitter:image"
				content="https://lucdn.letsupgrade.net/Trainerpreneur_1_793cf58531.jpg"
			/>
		</Head>
	)
}

export default HomeSeo
