import { Pagination } from "components"
import { useRouter } from "next/router"
import React, { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { useRecoilState, useRecoilValue } from "recoil"
import { resourceListState, statsState } from "recoil/statsState"
import api from "services/api"
import Resource from "./Resource"

const List = () => {
	const resourceList = useRecoilValue(resourceListState)
	const [stats, setStats] = useRecoilState(statsState)
	const [pageNo, setPageNo] = useState(1)
	const perPageOptions = [10, 20, 30, 40, 50]
	const perPage = perPageOptions[0]
	const { query: params } = useRouter()

	useEffect(() => {
		if (pageNo === stats.resourceList.page) return
		api.get(
			`/v2/lms/classes/files/${params.class}?page=${pageNo}&limit=${perPage}`
		)
			.then(res => {
				const resourceResData = res?.data?.results?.data
				setStats(prev => ({
					...prev,
					resourceList: {
						...prev.resourceList,
						resources: resourceResData?.messages,
						page: pageNo,
					},
				}))
			})
			.catch(e => {
				console.error(e)
				toast.error("Error fetching resources")
			})
	}, [pageNo])

	return resourceList.resources.length ? (
		<>
			<div className="flex flex-col space-y-4">
				<div className="grid grid-cols-1 gap-4 sm:grid-cols-3 md:grid-cols-4 md:gap-x-4">
					{resourceList.resources.map((resource, idx) => (
						<div
							className="w-full py-1.5 focus:bg-red-900"
							key={`${resource.user?.username}-${idx}`}
						>
							<Resource resource={resource} />
						</div>
					))}
				</div>
				<Pagination
					setPageNo={setPageNo}
					pageNo={pageNo}
					perPage={perPage}
					total={resourceList.total}
				/>
			</div>
		</>
	) : (
		<div className="flex h-full select-none flex-col items-center justify-center space-x-1 space-y-8 text-black">
			<img
				src="/noResource.png"
				alt="No Resource's Listed"
				width="200px"
			/>
			<span className="text-gray-500">No Resource's Listed</span>
		</div>
	)
}

export default List
