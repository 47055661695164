import React, { useEffect } from "react"

const OTP_SIZE = 6
const OTP_INPUTS = Array.from({ length: 10 }, (_, i) => i)
const OTP_INPUTS_NOS = Array.from({ length: 10 }, (_, i) => 48 + i)
const OTP_INPUTS_NUM_PAD = Array.from({ length: 10 }, (_, i) => 96 + i)

const OTPField = ({ setOtp, handleSubmit }) => {
	const inputfocus = e => {
		if (
			OTP_INPUTS_NUM_PAD.includes(e.which) ||
			OTP_INPUTS_NOS.includes(e.which) ||
			OTP_INPUTS.includes(e.target.valueAsNumber)
		) {
			if ([8, 46].includes(e.keyCode)) {
				e.target.value = ""
				const prev = e.target.tabIndex - 1
				if (prev > -1) {
					e.target.form.elements[prev].focus()
				}
			} else {
				const next = e.target.tabIndex + 1
				if (next <= OTP_SIZE) {
					e.target.form.elements[next].focus()
				}
				if (next > OTP_SIZE) handleSubmit()
			}
		}
	}

	return (
		<>
			<div className="grid grid-cols-6 gap-2">
				{[...Array(OTP_SIZE)].map((_, i) => (
					<input
						key={i + 1}
						type="number"
						required
						name={`otp-${i + 1}`}
						id={`otp-${i + 1}`}
						tabIndex={i + 1}
						min="0"
						max="9"
						maxLength={1}
						autoFocus={i === 0}
						className="block h-10 appearance-none rounded-md border border-gray-300 p-2 text-center placeholder-gray-400 shadow-sm focus:border-lu-500 focus:outline-none focus:ring-lu-500 sm:text-sm"
						onKeyUp={inputfocus}
						onChange={e =>
							setOtp(prev => {
								prev[i] = e.target.value
								return [...prev]
							})
						}
					/>
				))}
			</div>
		</>
	)
}

export default OTPField
