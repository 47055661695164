import { AtSymbolIcon } from "@heroicons/react/outline";
import { useState } from "react";
import { toast } from "react-toastify";
import { useRecoilValue } from "recoil";
import { userState } from "recoil/authState";
import AuthService from "services/auth.service";

const Social = () => {
	const user = useRecoilValue(userState);
	const [loading, setLoading] = useState(false);
	const [socialProfile, setSocialProfile] = useState({
		social: {
			facebook: user?.social?.facebook || "facebook",
			instagram: user?.social?.instagram || "instagram",
			linkedin: user?.social?.linkedin || "linkedin",
			github: user?.social?.github || "github",
		},
		introVideoLink: user?.introVideoLink || "https://drive.google.com/open?id=1dA4D4Bys7SDm3SlghEeYrXVG6xAwi9EX",
		resumeLink: user?.resumeLink || "https://drive.google.com/open?id=1dA4D4Bys7SDm3SlghEeYrXVG6xAwi9EX",
	});

	const handleProfileUpdate = (e) => {
		e.preventDefault();
		setLoading(true);
		AuthService.updateProfile({ data: socialProfile, _id: user?._id })
			.then(() => {
				setLoading(false);
				toast.success("Profile Updated");
			})
			.catch((err) => {
				setLoading(false);
				toast.error(err.message);
			});
	};

	return (
		<>
			<div className='bg-gray-50 md:p-12 py-12 px-8 rounded-xl'>
				<div className='md:grid md:grid-cols-3 md:gap-6'>
					<div className='md:col-span-12'>
						<div className='px-4 space-y-3 sm:px-6'>
							<h3 className='md:text-3xl text-2xl font-bold leading-6 text-gray-900'>
								Social
							</h3>
							<p className='mt-1 font-normal text-gray-500'>
								This information will be displayed publicly so be careful what you
								share.
							</p>
						</div>
					</div>
					<div className='mt-5 md:mt-0 md:col-span-12'>
						<form onSubmit={handleProfileUpdate}>
							<div className='shadow sm:rounded-md sm:overflow-hidden'>
								<div className='px-4 py-5 bg-white space-y-6 sm:p-6'>
									<div className='grid grid-cols-6 gap-6'>
										<div className='col-span-6 sm:col-span-3'>
											<label
												htmlFor='facebook'
												className='block text-sm font-medium text-gray-700'
											>
												Facebook
											</label>
											<div className='mt-1 flex rounded-md shadow-sm'>
												<span className='inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm'>
													<AtSymbolIcon className='w-4 h-4' />
												</span>
												<input
													type='text'
													name='facebook'
													id='facebook'
													className='focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300'
													placeholder='www.example.com'
													value={socialProfile.social.facebook}
													onChange={(e) =>
														setSocialProfile((prev) => ({
															...prev,
															social: {
																...prev.social,
																facebook: e.target.value,
															},
														}))
													}
												/>
											</div>
										</div>
										<div className='col-span-6 sm:col-span-3'>
											<label
												htmlFor='instagram'
												className='block text-sm font-medium text-gray-700'
											>
												Instagram
											</label>
											<div className='mt-1 flex rounded-md shadow-sm'>
												<span className='inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm'>
													<AtSymbolIcon className='w-4 h-4' />
												</span>
												<input
													type='text'
													name='instagram'
													id='instagram'
													className='focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300'
													placeholder='www.example.com'
													value={socialProfile.social.instagram}
													onChange={(e) =>
														setSocialProfile((prev) => ({
															...prev,
															social: {
																...prev.social,
																instagram: e.target.value,
															},
														}))
													}
												/>
											</div>
										</div>
										<div className='col-span-6 sm:col-span-3'>
											<label
												htmlFor='linkedin'
												className='block text-sm font-medium text-gray-700'
											>
												LinkedIn
											</label>
											<div className='mt-1 flex rounded-md shadow-sm'>
												<span className='inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm'>
													<AtSymbolIcon className='w-4 h-4' />
												</span>
												<input
													type='text'
													name='linkedin'
													id='linkedin'
													className='focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300'
													placeholder='www.example.com'
													value={socialProfile.social.linkedin}
													onChange={(e) =>
														setSocialProfile((prev) => ({
															...prev,
															social: {
																...prev.social,
																linkedin: e.target.value,
															},
														}))
													}
												/>
											</div>
										</div>
										<div className='col-span-6 sm:col-span-3'>
											<label
												htmlFor='github'
												className='block text-sm font-medium text-gray-700'
											>
												Github
											</label>
											<div className='mt-1 flex rounded-md shadow-sm'>
												<span className='inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm'>
													<AtSymbolIcon className='w-4 h-4' />
												</span>
												<input
													type='text'
													name='github'
													id='github'
													className='focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300'
													placeholder='www.example.com'
													value={socialProfile.social.github}
													onChange={(e) =>
														setSocialProfile((prev) => ({
															...prev,
															social: {
																...prev.social,
																github: e.target.value,
															},
														}))
													}
												/>
											</div>
										</div>
										<div className='col-span-6'>
											<label
												htmlFor='introVideo'
												className='block text-sm font-medium text-gray-700'
											>
												Intro Video Link
											</label>
											<div className='mt-1 flex rounded-md shadow-sm'>
												<span className='inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm'>
													https://
												</span>
												<input
													type='text'
													name='introVideo'
													id='introVideo'
													className='focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300'
													placeholder='www.example.com'
													value={socialProfile.introVideoLink}
													onChange={(e) =>
														setSocialProfile((prev) => ({
															...prev,
															introVideoLink: e.target.value,
														}))
													}
												/>
											</div>
										</div>
										<div className='col-span-6'>
											<label
												htmlFor='resume'
												className='block text-sm font-medium text-gray-700'
											>
												Latest Resume Link
											</label>
											<div className='mt-1 flex rounded-md shadow-sm'>
												<span className='inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm'>
													https://
												</span>
												<input
													type='text'
													name='resume'
													id='resume'
													className='focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300'
													placeholder='www.example.com'
													value={socialProfile.resumeLink}
													onChange={(e) =>
														setSocialProfile((prev) => ({
															...prev,
															resumeLink: e.target.value,
														}))
													}
												/>
											</div>
										</div>
									</div>
								</div>
								<div className='px-4 py-3 bg-gray-50 text-right sm:px-6 border-t border-gray-200'>
									<button
										type='submit'
										className='inline-flex justify-center border border-transparent text-sm focus:outline-none w-max bg-black text-gray-100 px-8 py-3 rounded-full tracking-wide font-semibold shadow-lg cursor-pointer'
									>
										{loading ? "Saving..." : "Save"}
									</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</>
	);
};

export default Social;
