import Head from "next/head"
import React from "react"

const ClassesSeo = () => {
	return (
		<Head>
			<title>LU Trainer Console | All Classes</title>
		</Head>
	)
}

export default ClassesSeo
