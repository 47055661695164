import { useRecoilValue } from "recoil";
import { assignmentQuestionState } from "recoil/statsState";
import Assignment from "./Assignment";
import AssignmentForm from "./AssignmentForm";

const AssignmentList = () => {
	const question = useRecoilValue(assignmentQuestionState);

	return (
		<>
			<div className='flex flex-col justify-start'>
				{!!question ? <Assignment /> : <AssignmentForm />}
			</div>
		</>
	);
};

export default AssignmentList;
