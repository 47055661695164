import { Dialog, Transition } from "@headlessui/react"
import { Fragment, useState } from "react"

const Short = ({ short }) => {
	const [isOpen, setIsOpen] = useState(false)

	const openModal = () => setIsOpen(true)
	const closeModal = () => setIsOpen(false)

	return (
		<>
			<div
				className="aspect-[5/4] w-full select-none overflow-hidden rounded-md border border-gray-800 hover:border-gray-200"
				onClick={openModal}
			>
				<div className="overlflow-hidden group relative h-full w-full cursor-pointer rounded-md bg-gray-500 pt-[56.25%]">
					<img
						className="absolute top-0 left-0 h-full w-full cursor-pointer opacity-70 group-hover:opacity-80"
						src={`https://img.youtube.com/vi/${short}/hqdefault.jpg`}
					/>
					<div className="absolute top-1/2 left-1/2 z-10 flex h-14 w-20 -translate-x-1/2 -translate-y-1/2 cursor-pointer items-center justify-center rounded-md bg-gray-700 opacity-80 shadow-[0_0_15px_rgba(0,0,0,0.6)] [border-color:transparent_transparent_transparent_#fff] group-hover:shadow-[0_0_30px_rgba(0,0,0,0.6)]">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							fill="currentColor"
							viewBox="0 0 24 24"
							strokeWidth={1.5}
							stroke="currentColor"
							className="h-8 w-8"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z"
							/>
						</svg>
					</div>
				</div>
			</div>
			<Transition
				appear
				show={isOpen}
				as={Fragment}
			>
				<Dialog
					as="div"
					className="relative z-10"
					onClose={closeModal}
				>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-black bg-opacity-25" />
					</Transition.Child>

					<div className="fixed inset-0 overflow-y-auto">
						<div className="flex min-h-full items-center justify-center p-4 text-center">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 scale-95"
								enterTo="opacity-100 scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 scale-100"
								leaveTo="opacity-0 scale-95"
							>
								<Dialog.Panel className="relative flex aspect-video h-[60vh] transform flex-col space-y-2 overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
									{/* <Dialog.Title
										as="h3"
										className="text-lg font-medium leading-6 text-gray-900"
									>
										Payment successful
									</Dialog.Title> */}
									<div className="absolute inset-0 z-0 flex h-full w-full items-center justify-center">
										<svg
											fill="none"
											viewBox="0 0 24 24"
											className="h-8 w-8 animate-spin"
										>
											<circle
												className="opacity-25"
												cx="12"
												cy="12"
												r="10"
												stroke="#ff66005e"
												strokeWidth="4"
											/>
											<path
												fill="#ff6600"
												d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
											/>
										</svg>
									</div>
									<iframe
										src={`https://youtube.com/embed/${short}`}
										className="relative z-10 h-full w-full rounded-md"
									/>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition>
		</>
	)
}

export default Short
