import { CheckCircleIcon as CheckCircleIconSolid } from "@heroicons/react/solid"
import { CheckCircleIcon as CheckCircleIconOutline } from "@heroicons/react/outline"
import classNames from "utils/classNames"

const filters = [
	{ key: "all", label: "My Schedule" },
	{ key: "pgp", label: "PGP FS Classes" },
	{ key: "essential", label: "Essential Classes" },
	{ key: "bootcamp", label: "Bootcamp Sessions" },
]

const ClassFilters = ({ selectedFilters, setSelectedFilters }) => {
	const toggleFilter = filter => {
		if (filter === "all" && !selectedFilters.includes("all")) {
			setSelectedFilters(["all"])
		} else if (filter !== "all") {
			const filterIdx = selectedFilters.indexOf(filter)

			if (filterIdx === -1) {
				setSelectedFilters(prev => {
					if (prev.includes("all")) {
						prev = prev.filter(p => p !== "all")
					}
					prev = [...prev, filter]
					return prev
				})
			} else {
				setSelectedFilters(prev => {
					prev = [
						...prev.slice(0, filterIdx),
						...prev.slice(filterIdx + 1),
					]
					if (prev.length === 0) prev = ["all"]
					return prev
				})
			}
		}
	}
	return (
		<>
			<div className="flex w-full select-none flex-col">
				<h2 className="text-base font-medium text-gray-900">Filters</h2>
				<ol className="mt-4 grid grid-cols-2 gap-2 text-sm leading-6 text-gray-500 md:block md:space-y-3">
					{filters.map(filter => (
						<li
							key={filter.key}
							className={classNames(
								"group relative flex cursor-pointer items-center justify-between rounded-md border-gray-300 bg-white hover:bg-gray-100 md:p-2",
								selectedFilters.includes(filter.key) ? "" : ""
							)}
							onClick={() => toggleFilter(filter.key)}
						>
							<span>{filter.label}</span>
							{selectedFilters.includes(filter.key) ? (
								<CheckCircleIconSolid
									className="h-5 w-5 text-gray-900"
									aria-hidden="true"
								/>
							) : (
								<CheckCircleIconOutline
									className="h-5 w-5 text-gray-300 group-hover:text-gray-400"
									aria-hidden="true"
								/>
							)}
						</li>
					))}
				</ol>
			</div>
		</>
	)
}

export default ClassFilters
