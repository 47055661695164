import { Tab } from "@headlessui/react"
import {
	BookOpenIcon,
	ClipboardListIcon,
	DocumentReportIcon,
	FolderIcon,
	UserGroupIcon,
} from "@heroicons/react/solid"
import { useEffect, useState } from "react"
import { useRecoilState, useResetRecoilState } from "recoil"
import { queryState, tabState } from "recoil/tabState"
import classNames from "utils/classNames"
import AgendaList from "./AgendaList"
import AssignmentList from "./AssignmentList"
import FeedbackList from "./FeedbackList"
import ParticipantList from "./ParticipantList"
import ResourceList from "./ResourceList"

const getWidth = () =>
	window.innerWidth ||
	document.documentElement.clientWidth ||
	document.body.clientWidth

const ClassStats = () => {
	let [categories] = useState([
		{ text: "Agenda", icon: ClipboardListIcon },
		{ text: "Assignment", icon: BookOpenIcon },
		{ text: "Students", icon: UserGroupIcon },
		// { text: "Doubts", icon: HandIcon },
		{ text: "PasteBin", icon: FolderIcon },
		// { text: "Feedbacks", icon: DocumentReportIcon },
	])
	const [isMD, setIsMD] = useState(getWidth() >= 768)
	let [activeTab, setActiveTab] = useRecoilState(tabState)
	const resetSearch = useResetRecoilState(queryState)

	useEffect(() => {
		const check = () => setIsMD(getWidth() >= 768)
		window.addEventListener("resize", check)
		return () => window.removeEventListener("resize", check)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<div className="w-full px-2 sm:px-0">
			<Tab.Group
				key={activeTab}
				defaultIndex={activeTab}
				onChange={index => {
					setActiveTab(index)
					resetSearch()
				}}
			>
				<Tab.List className="isolate flex divide-x divide-gray-200 overflow-hidden rounded-t-lg shadow">
					{categories?.map(category => (
						<Tab
							key={category.text}
							className={({ selected }) =>
								classNames(
									"group relative flex min-w-0 flex-1 items-center justify-center overflow-hidden bg-white py-4 px-4 text-center text-sm font-medium hover:bg-gray-50 hover:text-gray-700 focus:z-10",
									selected ? "text-gray-900" : "text-gray-500"
								)
							}
						>
							{({ selected }) => (
								<>
									{isMD ? (
										<span>{category.text}</span>
									) : (
										<category.icon className="h-5 w-5 sm:h-5 sm:w-5 md:h-6 md:w-6" />
									)}
									<span
										aria-hidden="true"
										className={classNames(
											"absolute inset-x-0 bottom-0 h-0.5",
											selected
												? "bg-lu-500"
												: "bg-transparent"
										)}
									/>
								</>
							)}
						</Tab>
					))}
				</Tab.List>
				<Tab.Panels>
					<Tab.Panel className="rounded-b-md bg-white p-6 shadow">
						<AgendaList />
					</Tab.Panel>
					<Tab.Panel className="rounded-b-md bg-white p-6 shadow">
						<AssignmentList />
					</Tab.Panel>
					<Tab.Panel className="rounded-b-md bg-white p-6 shadow">
						<ParticipantList />
					</Tab.Panel>
					{/* <Tab.Panel className="rounded-b-md bg-white p-6 shadow">
						<DoubtList />
					</Tab.Panel> */}
					<Tab.Panel className="rounded-b-md bg-white p-6 shadow">
						<ResourceList />
					</Tab.Panel>
					{/* <Tab.Panel className="rounded-b-md bg-white p-6 shadow">
						<FeedbackList />
					</Tab.Panel> */}
				</Tab.Panels>
			</Tab.Group>
		</div>
	)
}

export default ClassStats
