import { SearchIcon } from "@heroicons/react/outline"
import { Pagination } from "components"
import { useRouter } from "next/router"
import { useCallback, useEffect, useState } from "react"
import { toast } from "react-toastify"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import { userState } from "recoil/authState"
import { participantListState, statsState } from "recoil/statsState"
import { doubtsIndexState, queryState, tabState } from "recoil/tabState"
import api from "services/api"
import debounce from "utils/debounce"

const ParticipantList = () => {
	const user = useRecoilValue(userState)
	const participantList = useRecoilValue(participantListState)
	let setActiveTab = useSetRecoilState(tabState)
	let setDoubtQuery = useSetRecoilState(queryState)
	let tabIndex = useRecoilValue(doubtsIndexState)
	const [searchResult, setSearchResult] = useState()
	const [stats, setStats] = useRecoilState(statsState)
	const [pageNo, setPageNo] = useState(1)
	const perPageOptions = [10, 20, 30, 40, 50]
	const perPage = perPageOptions[0]
	const { query: params } = useRouter()

	const makeDoubtQuery = id => {
		setDoubtQuery({ type: "user", query: id })
		setActiveTab(tabIndex)
	}

	const searchStudent = e => {
		const { value: search } = e.target
		if (search.trim().length) {
			api.get(
				`/v2/admin/search/classes/enrolled/${params.class}?text=${search}`
			)
				.then(res => {
					setSearchResult(
						res.data.results.data?.sort(
							(b, a) => a.totalTime - b.totalTime
						)
					)
				})
				.catch(err => {
					console.error(err)
					toast.error("Error searching for student")
				})
		} else {
			setSearchResult(null)
		}
	}

	const debounceSearch = useCallback(debounce(searchStudent, 500), [])

	useEffect(() => {
		if (pageNo === stats.participantList.page) return
		api.get(
			`/v2/lms/classes/members/${params.class}?page=${pageNo}&limit=${perPage}`
		)
			.then(res => {
				const membersResData = res?.data?.results?.data
				setStats(prev => ({
					...prev,
					participantList: {
						...prev.participantList,
						participants: membersResData?.members?.sort(
							(b, a) => a.totalTime - b.totalTime
						),
						page: pageNo,
					},
				}))
			})
			.catch(e => {
				console.error(e)
				toast.error("Error getting Class Members")
			})
	}, [pageNo])

	return (
		<>
			{participantList.participants?.length ? (
				<div className="flex flex-col space-y-4">
					<div className="relative flex h-12 rounded-md shadow-sm">
						<span className="absolute inset-y-0 left-0 inline-flex items-center px-3 pl-4 text-sm text-gray-500">
							<SearchIcon className="h-4 w-4" />
						</span>
						<input
							type="text"
							onChange={debounceSearch}
							className="block w-full rounded-md border-gray-300 pl-12 shadow-sm focus:border-lu-500 focus:ring-lu-500 sm:text-sm"
							placeholder="Search Student"
						/>
					</div>
					<table className="block h-full w-full table-auto divide-y divide-gray-200 overflow-y-scroll text-sm scrollbar-hide">
						<thead className="border-b-[1px] border-b-gray-200 bg-gray-50">
							<tr>
								<th
									scope="col"
									className="px-6 py-3 text-center text-xs font-medium uppercase tracking-wider text-gray-500"
								>
									Sr. no.
								</th>
								<th
									scope="col"
									className="px-6 pr-3 pl-20 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
								>
									Name
								</th>
								<th
									scope="col"
									className="px-6 text-center text-xs font-medium uppercase tracking-wider text-gray-500"
								>
									Total Time
								</th>
								{/* <th
									scope="col"
									className="px-6 text-center text-xs font-medium uppercase tracking-wider text-gray-500"
								>
									Doubts
								</th> */}
							</tr>
						</thead>
						<tbody className="divide-y divide-gray-200 bg-white">
							{(searchResult?.length
								? searchResult
								: participantList.participants
							)?.map(({ user, totalTime, doubtCount }, idx) => (
								<tr key={user.uid}>
									<td className="whitespace-nowrap px-6 py-2 text-center">
										<div className="text-sm text-gray-900">
											{pageNo * perPage +
												idx +
												1 -
												perPage}
										</div>
									</td>
									<td className="whitespace-nowrap px-6 py-2">
										<div className="flex items-center">
											<div className="flex h-7 w-7 flex-shrink-0 items-center justify-center">
												<img
													className="h-7 w-7 rounded-md"
													src={
														user.profileImageUrl ||
														`https://avatars.dicebear.com/api/initials/${user.name}.svg`
													}
													alt={user.username}
												/>
											</div>
											<div className="ml-4">
												<a
													href={`https://letsupgrade.in/user/${user.username}`}
													target="_blank"
													className="text-black hover:text-lu-500"
												>
													{user.name}
												</a>
											</div>
										</div>
									</td>
									<td className="whitespace-nowrap px-6 py-2 text-center">
										<div className="text-sm text-gray-900">
											{totalTime
												? totalTime > 60
													? totalTime / 60 > 60
														? `${Math.round(
																totalTime / 3600
														  )} hrs`
														: `${Math.round(
																totalTime / 60
														  )} mins`
													: `${Math.round(
															totalTime
													  )} s`
												: "0 mins"}
										</div>
									</td>
									{/* <td className="whitespace-nowrap px-6 py-2 text-center">
										<div
											className="text-sm text-gray-900"
											// onClick={() =>
											// 	!!doubtCount &&
											// 	makeDoubtQuery(
											// 		user.name
											// 	)
											// }
										>
											{doubtCount || 0}
										</div>
									</td> */}
									{/* <td className='px-6 py-2 whitespace-nowrap'></td>
											<td className='px-6 py-2 whitespace-nowrap'></td> */}
								</tr>
							))}
						</tbody>
					</table>
					{!searchResult?.length && (
						<Pagination
							setPageNo={setPageNo}
							pageNo={pageNo}
							perPage={perPage}
							total={participantList.total}
						/>
					)}
				</div>
			) : (
				<div className="flex h-full select-none flex-col items-center justify-center space-x-1 space-y-8 text-black">
					<img
						src="/noParticipants.png"
						alt="No Participant's Listed"
						width="200px"
					/>
					<span className="text-gray-500">
						No Participant's Listed
					</span>
				</div>
			)}
		</>
	)
}

export default ParticipantList
