import { Dialog, Transition } from "@headlessui/react"
import { QuestionMarkCircleIcon } from "@heroicons/react/solid"
import { Pagination } from "components"
import { Fragment, useEffect, useState } from "react"
import { useRecoilValue } from "recoil"
import { userState } from "recoil/authState"
import api from "services/api"
import classNames from "utils/classNames"
import formatDate from "utils/formatDate"

const Leaderboard = () => {
	const user = useRecoilValue(userState)
	const [leaderboard, setLeaderboard] = useState()
	const [myScore, setMyScore] = useState()
	const [pageNo, setPageNo] = useState(1)

	const getLeaderboard = () => {
		api.get(`/v2/trainers/leaderboard/monthly?page=${pageNo}&limit=5`).then(
			resp => {
				resp = resp.data.results
				setLeaderboard(resp.data)
				if (!resp.data.map(t => t.trainer.uid).includes(user.uid))
					setMyScore({
						score: resp.score,
						rank: resp.rank,
						trainer: user,
					})
			}
		)
	}

	useEffect(getLeaderboard, [pageNo])

	return (
		<section aria-labelledby="leaderboard-title">
			<div className="overflow-hidden rounded-lg bg-white shadow">
				<div className="p-6">
					<h2
						className="flex items-center space-x-2 text-base font-medium text-gray-900"
						id="leaderboard-title"
					>
						<span>Leaderboard</span> <LeaderboardInfo />
					</h2>
					<span className="text-xs text-gray-400">
						Last Updated At: {formatDate(new Date()).baseDate}
					</span>
					<div className="mt-6 flow-root">
						<ul
							role="list"
							className="-my-5 divide-y divide-gray-200"
						>
							{leaderboard?.map(({ score, trainer }, idx) => (
								<li
									key={trainer.uid}
									className="flex items-center justify-between py-4"
								>
									<div className="flex items-center space-x-4">
										<div className="flex-shrink-0">
											#{idx + 1}
										</div>
										<div className="flex-shrink-0">
											<img
												className="h-8 w-8 rounded-md"
												src={
													trainer.profileImageUrl ||
													`https://avatars.dicebear.com/api/initials/${trainer.name}.svg`
												}
												alt=""
											/>
										</div>
										<div className="min-w-0 flex-1">
											<p className="truncate text-sm font-medium text-gray-900">
												{trainer.name}
											</p>
											<p className="truncate text-sm text-gray-500">
												{"@" + trainer.username}
											</p>
										</div>
									</div>
									<span className="truncate text-sm text-gray-500">
										{Math.ceil(score)}
									</span>
								</li>
							))}
							{myScore ? (
								<li className="flex items-center justify-between py-4">
									<div className="flex items-center space-x-4">
										<div className="flex-shrink-0">
											{myScore.rank === 0
												? "-"
												: "#" + myScore.rank + 1}
										</div>
										<div className="flex-shrink-0">
											<img
												className="h-8 w-8 rounded-md"
												src={
													myScore.trainer
														.profileImageUrl ||
													`https://avatars.dicebear.com/api/initials/${myScore.trainer.name}.svg`
												}
												alt=""
											/>
										</div>
										<div className="min-w-0 flex-1">
											<p className="truncate text-sm font-medium text-gray-900">
												{myScore.trainer.name}
											</p>
											<p className="truncate text-sm text-gray-500">
												{"@" + myScore.trainer.username}
											</p>
										</div>
									</div>
									<span className="truncate text-sm text-gray-500">
										{Math.ceil(myScore.score)}
									</span>
								</li>
							) : null}
						</ul>
					</div>
					<div className="mt-6">
						{/* <button
							type="button"
							disabled={leaderboard && leaderboard.length > 5}
							className={classNames(
								"flex w-full cursor-pointer items-center justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm enabled:hover:bg-gray-50 disabled:cursor-not-allowed disabled:text-gray-500"
							)}
						>
							View all
						</button> */}
						{/* <Pagination
							setPageNo={setPageNo}
							pageNo={pageNo}
							perPage={5}
							total={50}
						/> */}
					</div>
				</div>
			</div>
		</section>
	)
}

const LeaderboardInfo = () => {
	const [open, setOpen] = useState(false)
	const openInfo = () => setOpen(true)
	const closeInfo = () => setOpen(false)

	return (
		<>
			<QuestionMarkCircleIcon
				className="h-5 w-5 text-gray-500"
				onClick={openInfo}
			/>
			<Transition
				appear
				show={open}
				as={Fragment}
			>
				<Dialog
					as="div"
					className="relative z-10"
					onClose={closeInfo}
				>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-black bg-opacity-25" />
					</Transition.Child>

					<div className="fixed inset-0 overflow-y-auto">
						<div className="flex min-h-full items-center justify-center p-4 text-center">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 scale-95"
								enterTo="opacity-100 scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 scale-100"
								leaveTo="opacity-0 scale-95"
							>
								<Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
									{/* <Dialog.Title
									as="h3"
									className="text-lg font-medium leading-6 text-gray-900"
								>
									Payment successful
								</Dialog.Title> */}
									<div className="mt-2 flex flex-col space-y-4">
										<p className="text-sm text-gray-500">
											The scores in the leaderboard are
											calculated based on various positive
											and negative factors of the trainer.
										</p>
										<span>
											<p className="text-sm font-semibold text-gray-500">
												The score increases based on
											</p>
											<ol className="list-inside list-decimal text-sm text-gray-500">
												<li>
													Number of sessions the
													trainer has taken.
												</li>
												<li>
													Total duration of the
													sessions the trainer has
													completed.
												</li>
												<li>
													Average understanding level
													of all the sessions.
												</li>
												<li>
													Trainer NPS of all the
													sessions submitted by the
													students.
												</li>
												<li>
													Student attendance
													percentage compared to all
													the students that have
													access to the session.
												</li>
											</ol>
										</span>
										<span>
											<p className="text-sm font-semibold text-gray-500">
												The score will drastically go
												down if you
											</p>
											<ol className="list-inside list-decimal text-sm text-gray-500">
												<li>
													Are not in appropriate
													attire in the session.
												</li>
												<li>
													Do not show up to an already
													scheduled session.
												</li>
												<li>
													Reschedule the session last
													minute.
												</li>
												<li>
													Are not in a proper
													background.
												</li>
												<li>
													Do not use a camera of good
													quality or it is not working
													while the session is active.
												</li>
												<li>
													Have external disturbances
													in the session from your
													surrounding.
												</li>
											</ol>
										</span>
										<p className="text-sm text-gray-500">
											The above parameters are clubbed and
											a leaderboard is generated. The
											leaderboard will reset on the first
											day of the month.
										</p>
									</div>

									<div className="mt-4 flex justify-center">
										<button
											type="button"
											className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
											onClick={closeInfo}
										>
											Got It
										</button>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition>
		</>
	)
}

export default Leaderboard
