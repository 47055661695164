import { Dialog, Transition } from "@headlessui/react"
import { DownloadIcon, XIcon } from "@heroicons/react/outline"
import React, { Fragment, useState } from "react"

const Image = ({ resource }) => {
	const [showPreview, setShowPreview] = useState(false)

	const closePreview = () => {
		setShowPreview(false)
	}
	return (
		<>
			<div className="flex select-none flex-col space-x-2">
				<div className="flex min-w-[24px] content-center justify-center">
					<img
						src={resource.link}
						alt={resource.name}
						className="rounded-lg"
						onClick={() => {
							setShowPreview(true)
						}}
					/>
				</div>
				<p
					className="break-words text-sm"
					dangerouslySetInnerHTML={{
						__html: resource.text,
					}}
				/>
			</div>
			<Transition
				appear
				show={showPreview}
				as={Fragment}
			>
				<Dialog
					as="div"
					className="relative z-10"
					onClose={closePreview}
				>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-black bg-opacity-25" />
					</Transition.Child>

					<div className="fixed inset-0 overflow-y-auto">
						<div className="flex min-h-full items-center justify-center p-4 text-center">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 scale-95"
								enterTo="opacity-100 scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 scale-100"
								leaveTo="opacity-0 scale-95"
							>
								<Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
									<div className="flex items-center justify-between">
										<Dialog.Title
											as="h3"
											className="text-lg font-medium leading-6 text-gray-900"
										>
											{resource.name}
										</Dialog.Title>
										<div className="flex space-x-4">
											<a
												href={resource.link}
												download
												className="inline-flex justify-center rounded-lg bg-green-500 p-2 text-sm font-medium text-white hover:bg-green-600"
												onClick={() =>
													console.debug(
														"@Download: ",
														resource.link
													)
												}
											>
												<DownloadIcon className="h-4 w-4" />
											</a>
											<button
												type="button"
												className="inline-flex justify-center rounded-lg p-2 text-sm font-medium text-black hover:bg-gray-100"
												onClick={closePreview}
											>
												<XIcon className="h-4 w-4" />
											</button>
										</div>
									</div>
									<div className="mt-2 flex max-h-[75vh] flex-1 justify-center overflow-hidden p-5">
										<img
											src={resource.link}
											alt={resource.link}
										/>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition>
		</>
	)
}

export default Image
