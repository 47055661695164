import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid"
import {
	add,
	closestIndexTo,
	eachDayOfInterval,
	endOfMonth,
	format,
	getDay,
	isAfter,
	isBefore,
	isEqual,
	isSameMonth,
	isToday,
	isWithinInterval,
	parse,
	startOfToday,
} from "date-fns"
import { useEffect, useState } from "react"
import api from "services/api"
import classNames from "utils/classNames"

const Calendar = ({ dateRange, setDateRange, setShift, setClick }) => {
	const today = startOfToday()
	const [currentMonth, setCurrentMonth] = useState(format(today, "MMM-yyyy"))
	const [monthClasses, setMonthClasses] = useState([])
	const firstDayCurrentMonth = parse(currentMonth, "MMM-yyyy", new Date())

	const days = eachDayOfInterval({
		start: firstDayCurrentMonth,
		end: endOfMonth(firstDayCurrentMonth),
	})

	function previousMonth() {
		const firstDayNextMonth = add(firstDayCurrentMonth, { months: -1 })
		setCurrentMonth(format(firstDayNextMonth, "MMM-yyyy"))
	}

	function nextMonth() {
		const firstDayNextMonth = add(firstDayCurrentMonth, { months: 1 })
		setCurrentMonth(format(firstDayNextMonth, "MMM-yyyy"))
	}

	const handleDayClick = (e, day) => {
		if (
			dateRange.length === 1 &&
			dateRange[0].toISOString() === day.toISOString()
		)
			return
		setDateRange(prev => {
			if (e.shiftKey) {
				if (
					prev
						.map(d => d.toISOString() === day.toISOString())
						.some(d => d === true)
				)
					return prev
				if (isBefore(prev[0], today)) return [day]
				if (isBefore(day, today)) return [day]
				if (
					prev.length === 2 &&
					isWithinInterval(day, { start: prev[0], end: prev[1] })
				) {
					const idx = closestIndexTo(day, prev)
					prev[idx] = day
					return [...prev]
				} else if (isAfter(day, prev[prev.length === 1 ? 0 : 1]))
					return [prev[0], day]
				else if (isBefore(day, prev[0]))
					return [day, prev[prev.length === 1 ? 0 : 1]]
			}
			return [day]
		})
	}

	const getCurrentMonthClasses = () => {
		api.get(
			`/v2/trainers/classes/month?date=${firstDayCurrentMonth.toISOString()}`
		).then(monthResp => {
			monthResp = monthResp.data.results.data.map(d => d._id)
			setMonthClasses(monthResp)
		})
	}

	useEffect(() => getCurrentMonthClasses(), [currentMonth])

	const setValue = (fn, newVal) =>
		fn(prev => (prev === newVal ? prev : newVal))

	useEffect(() => {
		const dateButtons = document.querySelectorAll(".date")
		dateButtons.forEach(btn => {
			btn.addEventListener("mousedown", _ => setValue(setClick, true))
			btn.addEventListener("mouseup", _ => setValue(setClick, false))
		})
		document.addEventListener("keydown", ({ key }) => {
			if (key === "Shift") setValue(setShift, true)
		})
		document.addEventListener("keyup", ({ key }) => {
			if (key === "Shift") setValue(setShift, false)
		})
		return _ => {
			dateButtons.forEach(btn => {
				btn.removeEventListener("mousedown", _ =>
					setValue(setClick, true)
				)
				btn.removeEventListener("mouseup", _ =>
					setValue(setClick, false)
				)
			})
			document.removeEventListener("keydown", ({ key }) => {
				if (key === "Shift") setValue(setShift, true)
			})
			document.removeEventListener("keyup", ({ key }) => {
				if (key === "Shift") setValue(setShift, false)
			})
		}
	}, [])

	return (
		<div className="grid select-none grid-cols-1">
			<div className="flex items-center justify-between">
				<button
					type="button"
					onClick={previousMonth}
					className="-my-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
				>
					<span className="sr-only">Previous month</span>
					<ChevronLeftIcon
						className="h-5 w-5"
						aria-hidden="true"
					/>
				</button>
				<h2 className="text-base font-medium text-gray-900">
					{format(firstDayCurrentMonth, "MMMM yyyy")}
				</h2>
				<button
					onClick={nextMonth}
					type="button"
					className="-my-1.5 -mr-1.5 ml-2 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
				>
					<span className="sr-only">Next month</span>
					<ChevronRightIcon
						className="h-5 w-5"
						aria-hidden="true"
					/>
				</button>
			</div>
			<div className="mt-10 grid grid-cols-7 text-center text-xs leading-6 text-gray-500">
				<div>S</div>
				<div>M</div>
				<div>T</div>
				<div>W</div>
				<div>T</div>
				<div>F</div>
				<div>S</div>
			</div>
			<div className="mt-2 grid grid-cols-7 text-sm">
				{days.map((day, dayIdx) => (
					<div
						key={day.toString()}
						className={classNames(
							dayIdx === 0 && colStartClasses[getDay(day)],
							"py-1.5"
						)}
					>
						<button
							type="button"
							onClick={e => handleDayClick(e, day)}
							className={classNames(
								// isEqual(day, dateRange[0]) && "text-white",
								!isEqual(day, dateRange[0]) &&
									isToday(day) &&
									"text-orange-900",
								!isEqual(day, dateRange[0]) &&
									!isToday(day) &&
									isSameMonth(day, firstDayCurrentMonth) &&
									"text-gray-900",
								!isEqual(day, dateRange[0]) &&
									!isToday(day) &&
									!isSameMonth(day, firstDayCurrentMonth) &&
									"text-gray-400",
								isEqual(day, dateRange[0]) &&
									isToday(day) &&
									"border border-orange-500 bg-orange-50 text-orange-900",
								isEqual(day, dateRange[0]) &&
									!isToday(day) &&
									"border border-gray-500 bg-gray-100 text-gray-900",
								!isEqual(day, dateRange[0]) &&
									"hover:bg-gray-200",
								(isEqual(day, dateRange[0]) || isToday(day)) &&
									"font-semibold",
								dateRange.length === 2 &&
									isWithinInterval(new Date(day), {
										start: dateRange[0],
										end: dateRange[1],
									}) &&
									"rounded-none border !border-gray-400 !bg-gray-100 font-semibold",
								dateRange.length === 2 &&
									isEqual(day, dateRange[0]) &&
									"rounded-l-md",
								dateRange.length === 2 &&
									isEqual(day, dateRange[1]) &&
									"rounded-r-md",
								"max-w-8 date relative flex aspect-square w-full items-center justify-center rounded-md border border-transparent"
							)}
						>
							{monthClasses.includes(day.toISOString()) ? (
								<span className="absolute bottom-1 left-1/2 h-0.5 w-2.5 -translate-x-1/2 rounded-full bg-orange-500" />
							) : null}
							<time dateTime={format(day, "yyyy-MM-dd")}>
								{format(day, "d")}
							</time>
						</button>
					</div>
				))}
			</div>
		</div>
	)
}

let colStartClasses = [
	"",
	"col-start-2",
	"col-start-3",
	"col-start-4",
	"col-start-5",
	"col-start-6",
	"col-start-7",
]

export default Calendar
