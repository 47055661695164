import { useRouter } from "next/router"
import { useState } from "react"
import { useSetRecoilState } from "recoil"
import { statsState } from "recoil/statsState"
import api from "services/api"
import Agenda from "./Agenda"
import AgendaForm from "./AgendaForm"

const AgendaList = () => {
	const setStats = useSetRecoilState(statsState)
	const [error, setError] = useState(false)
	const { query: params } = useRouter()

	const addAgenda = agenda => {
		if (!agenda.text || /^\s*$/.test(agenda.text)) {
			return
		}
		api.post(`/v2/lms/classes/agenda/${params.class}`, {
			agenda,
		})
			.then(() =>
				setStats(prev => ({
					...prev,
					agendaList: [...prev.agendaList, agenda],
				}))
			)
			.catch(e => {
				setError(true)
				console.error(e)
				toast.error("Error adding Agenda")
			})
	}

	const updateAgenda = agenda => {
		if (!agenda.text || /^\s*$/.test(agenda.text)) {
			return
		}
		api.post(`/v2/lms/classes/agenda/${params.class}`, {
			agenda,
		})
			.then(() =>
				setStats(prev => ({
					...prev,
					agendaList: prev.agendaList.map(item =>
						item.id === agenda.id ? agenda : item
					),
				}))
			)
			.catch(e => {
				setError(true)
				console.error(e)
				toast.error("Error updating Agenda")
			})
	}

	const removeAgenda = id => {
		api.delete(`/v2/lms/classes/agenda/${params.class}`, {
			data: { agenda: { id } },
		})
			.then(() =>
				setStats(prev => ({
					...prev,
					agendaList: prev.agendaList.filter(item => item.id !== id),
				}))
			)
			.catch(e => {
				setError(true)
				console.error(e)
				toast.error("Error removing Agenda")
			})
	}

	const completeAgenda = agenda => {
		api.post(`/v2/lms/classes/agenda/${params.class}`, {
			agenda,
		})
			.then(() =>
				setStats(prev => ({
					...prev,
					agendaList: prev.agendaList.map(item =>
						item.id === agenda.id ? agenda : item
					),
				}))
			)
			.catch(e => {
				setError(true)
				console.error(e)
				toast.error("Error completing Agenda")
			})
	}

	return (
		<>
			<div className="flex flex-col justify-start">
				<AgendaForm onSubmit={addAgenda} />
				<Agenda
					completeAgenda={completeAgenda}
					removeAgenda={removeAgenda}
					updateAgenda={updateAgenda}
				/>
			</div>
		</>
	)
}

export default AgendaList
