import { atom, selector } from "recoil";
import CookieService from "services/cookie.service";

export const tokenState = atom({
	key: "tokenState",
	default: {
		accessToken: CookieService.getLocalAccessToken() || null,
		refreshToken: CookieService.getLocalRefreshToken() || null,
	},
});

export const userState = atom({
	key: "userState",
	default: null,
});

export const loadingState = atom({
	key: "loadingState",
	default: true,
});

export const loginState = selector({
	key: "loginState",
	get: ({ get }) => {
		return !!get(tokenState).accessToken;
	},
});

export const accessTokenState = selector({
	key: "accessTokenState",
	get: ({ get }) => {
		return get(tokenState).accessToken;
	},
});

export const refreshTokenState = selector({
	key: "refreshTokenState",
	get: ({ get }) => {
		return get(tokenState).refreshToken;
	},
});

export const authState = selector({
	key: "authState",
	get: ({ get }) => {
		return get(auth);
	},
});