import { PencilIcon, TrashIcon } from "@heroicons/react/outline"
import parse from "html-react-parser"
import { useRouter } from "next/router"
import { useState } from "react"
import { toast } from "react-toastify"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { assignmentQuestionState, statsState } from "recoil/statsState"
import api from "services/api"
import AssignmentForm from "./AssignmentForm"

const Assignment = () => {
	const question = useRecoilValue(assignmentQuestionState)
	const setStats = useSetRecoilState(statsState)
	const [edit, setEdit] = useState(null)
	const { query: params } = useRouter()

	const handleDeleteAssignment = () => {
		api.post(`/v2/lms/assignment/${params.class}`, {
			question: null,
		})
			.then(() => {
				setStats(prev => ({
					...prev,
					assignmentQuestion: null,
				}))
				toast.warning("Assignment deleted")
			})
			.catch(() => {
				console.error(e)
				toast.error("Error deleting Assignment")
			})
	}

	return (
		<>
			{question ? (
				!edit ? (
					<>
						<span className="flex justify-between">
							<span className="ql-editor flex-1">
								{parse(question)}
							</span>
						</span>
						<div class="flex flex-shrink-0 justify-between space-x-3 border-t border-gray-200 pt-5">
							<button
								type="button"
								className="inline-flex h-full items-center space-x-2 rounded border border-gray-300 bg-white px-2.5 py-2 text-sm font-medium leading-5 text-red-500 shadow-sm hover:bg-gray-50"
								onClick={handleDeleteAssignment}
							>
								<TrashIcon className="h-5 w-5" />
								<span>Delete</span>
							</button>
							<button
								type="submit"
								className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-black py-2 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-0"
								onClick={() => setEdit(question)}
							>
								Edit
							</button>
						</div>
					</>
				) : (
					<AssignmentForm
						edit={edit}
						setEdit={setEdit}
					/>
				)
			) : null}
			{/* <AssignmentTable /> */}
		</>
	)
}

export default Assignment
