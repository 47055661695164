import { useRouter } from "next/router"
import React from "react"
import { useRecoilValue } from "recoil"
import { userState } from "recoil/authState"

const WPBtn = () => {
	const { pathname } = useRouter()
	const user = useRecoilValue(userState)

	if (
		pathname === "/console" &&
		user &&
		user.role.toLowerCase() !== "newtrainer"
	)
		return null
	else if (pathname !== "/") return null

	return (
		<div className="fixed bottom-8 right-8 z-50 rounded-full shadow-lg">
			<a
				href="https://api.whatsapp.com/send/?phone=918591939767&text=Hey+I+want+help+regarding+Trainerpreneur+program+&type=phone_number&app_absent=0"
				target="_blank"
				rel="noopener noreferrer"
			>
				<img
					src="https://lucdn.letsupgrade.net/whatsapp2_e3dafbd614.png"
					alt=""
				/>
			</a>
		</div>
	)
}

export default WPBtn
