import Head from "next/head"

const LoginSeo = () => {
	return (
		<Head>
			<title>LU | Login</title>
			<link
				rel="icon"
				href="/favicon.ico"
			/>
		</Head>
	)
}

export default LoginSeo
