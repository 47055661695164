import {
	ArrowSmRightIcon,
	PlayIcon,
	UserGroupIcon,
} from "@heroicons/react/solid"
import Link from "next/link"
import { useEffect, useState } from "react"
import classNames from "utils/classNames"

const ClassCard = ({ session }) => {
	return (
		<>
			<Link href={`/console/classes/${session._id}`}>
				<a className="group space-y-2 sm:flex md:space-x-6 md:space-y-0">
					<div className="flex flex-1 space-x-2">
						<span className="hidden md:block">
							<ClassTime session={session} />
						</span>
						<div className="flex flex-1 flex-col justify-center">
							{session.programs?.length ? (
								<span className="text-xs uppercase text-gray-300">
									{session.programs[0].title}
								</span>
							) : null}
							<span className="text-gray-900">
								{session.name}
							</span>
							{session.attendees ? (
								<span className="flex items-center space-x-2 text-xs text-gray-300">
									<UserGroupIcon className="h-3" />
									<span>{session.attendees} students</span>
								</span>
							) : null}
						</div>
						<div className="flex flex-col items-center justify-center space-y-2">
							<span className="md:hidden">
								<ClassTime session={session} />
							</span>
							<ArrowSmRightIcon className="mx-5 hidden w-8 -rotate-45 cursor-pointer group-hover:text-lu-500 md:block" />
						</div>
					</div>
				</a>
			</Link>
		</>
	)
}

const ClassTime = ({ session }) => {
	const [showLive, setShowLive] = useState(false)

	useEffect(() => {
		if (session.classStatus === "Live") {
			const showLiveInterval = setInterval(
				() => setShowLive(prev => !prev),
				2000
			)
			return () => clearInterval(showLiveInterval)
		}
	}, [])
	return (
		<span
			className={classNames(
				"flex w-max items-center justify-center rounded-md p-2 text-center font-medium text-black md:h-14 md:w-24 md:!p-0",
				session.statusColor,
				session.classStatus === "Finished" ? "group" : ""
			)}
		>
			{session.classStatus !== "Finished" ? (
				<>
					<time
						dateTime={session.classDate.time}
						className={classNames(
							session.classStatus === "Live"
								? showLive
									? "hidden"
									: "block"
								: "block"
						)}
					>
						{session.classDate.time}
					</time>
					{session.classStatus === "Live" ? (
						<span
							className={classNames(
								"uppercase",
								showLive ? "block" : "hidden"
							)}
						>
							Live
						</span>
					) : null}
				</>
			) : (
				<PlayIcon className="w-6 group-hover:scale-110" />
			)}
		</span>
	)
}

export default ClassCard
