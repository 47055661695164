import { atom, selector } from "recoil";

// export const tokenState = atom({
// 	key: "tokenState",
// 	default: {
// 		accessToken: CookieService.getLocalAccessToken() || null,
// 		refreshToken: CookieService.getLocalRefreshToken() || null,
// 	},
// });

export const tabState = atom({
	key: "tabState",
	default: 0,
});

export const queryState = atom({
	key: "queryState",
	default: { type: null, query: '' },
});

export const tabIndexState = atom({
	key: "tabIndexState",
	default: { agenda: 0, assignments: 1, participants: 2, doubts: 3, resources: 4, feedbacks: 5 },
});

export const agendaIndexState = selector({
	key: "agendaIndexState",
	get: ({ get }) => {
		return get(tabIndexState).agenda;
	},
});

export const participantsIndexState = selector({
	key: "participantsIndexState",
	get: ({ get }) => {
		return get(tabIndexState).participants;
	},
});

export const doubtsIndexState = selector({
	key: "doubtsIndexState",
	get: ({ get }) => {
		return get(tabIndexState).doubts;
	},
});

export const resourcesIndexState = selector({
	key: "resourcesIndexState",
	get: ({ get }) => {
		return get(tabIndexState).resources;
	},
});

export const feedbacksIndexState = selector({
	key: "feedbacksIndexState",
	get: ({ get }) => {
		return get(tabIndexState).feedbacks;
	},
});
