import { LinkPreview } from "@dhaiwat10/react-link-preview"
import formatDate from "utils/formatDate"
import File from "./File"
import Image from "./Image"

const Resource = ({ resource }) => {
	return (
		<>
			<div className="flex h-full flex-col space-y-4 rounded-lg border bg-white p-4 shadow hover:border-gray-300 hover:bg-gray-50">
				<div
					className="flex h-full flex-wrap items-center justify-evenly"
					onContextMenu={e => e.preventDefault()}
				>
					{resource.source === "file" ? (
						resource.isImage ? (
							<Image resource={resource} />
						) : (
							<File resource={resource} />
						)
					) : (
						resource.source === "input" && (
							<div className="h-full w-full">
								{resource.link.length ? (
									<LinkPreview
										url={
											typeof resource.link === "object"
												? resource.link[0]
												: resource.link
										}
										imageHeight={0}
										descriptionLength={5}
										borderRadius={8}
										openInNewTab={true}
										className="mb-2 break-words"
									/>
								) : null}
								<p
									className="break-words text-sm"
									dangerouslySetInnerHTML={{
										__html: resource.text,
									}}
								/>
								<span className="text-blue-500 hover:underline" />
							</div>
						)
					)}
				</div>
				<div className="flex flex-col space-y-1">
					<p className="text-xs text-gray-400">
						{formatDate(resource.createdAt).dateTime}
					</p>
					{resource.source !== "input" && (
						<div className="flex flex-wrap items-center justify-center">
							<a
								className="w-full rounded-md border border-gray-300 bg-white py-2 px-4 text-center text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-0"
								href={resource.link}
								download
								onClick={() =>
									console.debug(
										"@Download: ",
										resource.name ||
											resource.link
												.split("/")
												.at(-1)
												.split("_")
												.at(-1)
									)
								}
							>
								Download
							</a>
						</div>
					)}
				</div>
			</div>
		</>
	)
}

export default Resource
