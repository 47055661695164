import { Listbox, Transition } from "@headlessui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/outline";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import { Fragment, useState } from "react";
import countryCodes from "utils/countryCodes";

const SocialDetails = ({ handleNext, handleBack, handleComplete, registerForm, setRegisterForm }) => {
	const [selected, setSelected] = useState(countryCodes[0]);

	return (
		<>
			<form className='mt-8 space-y-6'>
				<input type='hidden' name='remember' defaultValue='true' />
				<div className='mt-8 content-center'>
					<label className='text-sm font-bold text-gray-700 tracking-wide'>Name</label>
					<input
						className='w-full text-base px-4 py-2 border-b border-gray-300 focus:outline-none rounded focus:border-lu-500'
						placeholder='mail@domain.com'
						value={registerForm.name}
						onChange={(e) =>
							setRegisterForm((prev) => ({
								...prev,
								name: e.target.value,
							}))
						}
					/>
				</div>
				<div className='mt-8 content-center'>
					<label className='text-sm font-bold text-gray-700 tracking-wide'>
						Phone Number
					</label>
					<div className='w-full flex space-x-3'>
						<Listbox
							value={selected}
							onChange={(e) => {
								setSelected(e);
								setRegisterForm((prev) => ({
									...prev,
									phone: {
										...registerForm.phone,
										code: e.dial_code,
									},
								}));
							}}
						>
							<div className='relative mt-1 flex-1'>
								<Listbox.Button className='relative w-full py-2 pl-3 pr-10 text-left bg-white rounded-lg shadow cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-sm'>
									<span className='block truncate'>
										{selected.flag} {selected.dial_code}
									</span>
									<span className='absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none'>
										<SelectorIcon
											className='w-5 h-5 text-gray-400'
											aria-hidden='true'
										/>
									</span>
								</Listbox.Button>
								<Transition
									as={Fragment}
									leave='transition ease-in duration-100'
									leaveFrom='opacity-100'
									leaveTo='opacity-0'
								>
									<Listbox.Options className='absolute w-60 py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
										{countryCodes.map((country, countryIdx) => (
											<Listbox.Option
												key={countryIdx}
												className={({ active }) =>
													`${
														active
															? "text-amber-900 bg-amber-100"
															: "text-gray-900"
													}
																	cursor-default select-none relative py-2 pl-10 pr-4`
												}
												value={country}
											>
												{({ selected, active }) => (
													<>
														<span
															className={`${
																selected
																	? "font-medium"
																	: "font-normal"
															} block truncate`}
														>
															{country.flag} {country.name} (
															{country.dial_code})
														</span>
														{selected ? (
															<span
																className={`${
																	active
																		? "text-amber-600"
																		: "text-amber-600"
																}
																				absolute inset-y-0 left-0 flex items-center pl-3`}
															>
																<CheckIcon
																	className='w-5 h-5'
																	aria-hidden='true'
																/>
															</span>
														) : null}
													</>
												)}
											</Listbox.Option>
										))}
									</Listbox.Options>
								</Transition>
							</div>
						</Listbox>
						<input
							className='flex-1 text-base px-4 py-2 border-b border-gray-300 focus:outline-none rounded focus:border-lu-500'
							placeholder='mail@domain.com'
							value={registerForm.phone.number}
							onChange={(e) =>
								setRegisterForm((prev) => ({
									...prev,
									phone: {
										...registerForm.phone,
										number: e.target.value,
									},
								}))
							}
						/>
					</div>
				</div>
				<div className='mt-8 content-center'>
					<label className='text-sm font-bold text-gray-700 tracking-wide'>Email</label>
					<input
						className='w-full text-base px-4 py-2 border-b border-gray-300 focus:outline-none rounded focus:border-lu-500'
						placeholder='mail@domain.com'
						value={registerForm.email}
						onChange={(e) =>
							setRegisterForm((prev) => ({
								...prev,
								email: e.target.value,
							}))
						}
					/>
				</div>
				<div className='mt-8 content-center'>
					<label className='text-sm font-bold text-gray-700 tracking-wide'>
						Password
					</label>
					<input
						className='w-full content-center text-base px-4 py-2 border-b rounded border-gray-300 focus:outline-none focus:border-lu-500'
						type='password'
						placeholder='********'
						value={registerForm.password}
						onChange={(e) =>
							setRegisterForm((prev) => ({
								...prev,
								password: e.target.value,
							}))
						}
					/>
				</div>
				<div className='flex justify-evenly'>
					<button
						type='button'
						onClick={handleComplete}
						className='w-max flex justify-center bg-lu-500 text-gray-100 p-4  rounded tracking-wide font-semibold  shadow-lg cursor-pointer transition ease-in duration-500'
					>
						Continue
					</button>
				</div>
				<div className='flex justify-between'>
					<button
						type='button'
						onClick={handleBack}
						className='w-max flex justify-center bg-gray-200 hover:bg-gray-300 text-gray-100 p-4 rounded-full tracking-wide font-semibold  shadow-lg cursor-pointer transition ease-in duration-500'
					>
						<ChevronLeftIcon className='w-4 h-4 text-black' />
					</button>
					<button
						type='button'
						onClick={handleNext}
						className='w-max flex justify-center bg-gray-200 hover:bg-gray-300 text-gray-100 p-4 rounded-full tracking-wide font-semibold  shadow-lg cursor-pointer transition ease-in duration-500'
					>
						<ChevronRightIcon className='w-4 h-4 text-black' />
					</button>
				</div>
			</form>
		</>
	);
};

export default SocialDetails;
