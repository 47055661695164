import React from "react";
import Input from "./Input";
import List from "./List";

const PasteBinList = () => {
	return (
		<>
			<Input />
			<List />
		</>
	);
};

export default PasteBinList;
