import axios from "axios"
// import { NextResponse } from "next/server";
import CookieService from "./cookie.service"

const axiosInstance = axios.create({
	baseURL: "https://service.letsupgrade.in",
	headers: {
		"Content-Type": "application/json",
	},
})

axiosInstance.interceptors.request.use(
	config => {
		const token = CookieService.getLocalAccessToken()

		if (token) {
			config.headers["Authorization"] = "Bearer " + token // for Spring Boot back-end
			// config.headers["x-access-token"] = token; // for Node.js Express back-end
		}
		return config
	},
	error => {
		return Promise.reject(error)
	}
)

axiosInstance.interceptors.response.use(
	res => {
		return res
	},
	async err => {
		const originalConfig = err.config

		if (err.response?.status !== 401) {
			// CookieService.removeTokens();
			return Promise.reject(err)
		}
		if (originalConfig.url === "/v2/auth/access") {
			// Refresh Token was expired
			CookieService.removeTokens()
			// router.push("/");
			// console.debug("Refresh Token was expired");
			// window.location = "/login";
			return Promise.reject(err)
		}

		return await axiosInstance
			.post("/v2/auth/access", {
				refresh: CookieService.getLocalRefreshToken(),
			})
			.then(resp => {
				const { token } = resp.data
				CookieService.updateLocalAccessToken(token)

				return axiosInstance(originalConfig)
			})
			.catch(_error => {
				return Promise.reject(_error)
			})
	}
)

export default axiosInstance
