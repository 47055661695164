import { toast } from "react-toastify"
import api from "./api"
import CookieService from "./cookie.service"

const AuthService = {
	login({ email, password }) {
		return api
			.post("/v2/auth/login", {
				email,
				password,
			})
			.then(tokenResp => {
				// console.debug(tokenResp.data)
				if (tokenResp.data.token) {
					CookieService.setTokens({
						accessToken: tokenResp.data.token,
						refreshToken: tokenResp.data.refresh,
					})
					return {
						accessToken: tokenResp.data.token,
						refreshToken: tokenResp.data.refresh,
					}
				}
			})
			.catch(e => {
				console.error(e)
				toast.error("Error logging in")
				return null
			})
	},

	getOtp({ phoneNumber, recaptchaToken }) {
		return api
			.post("/v2/otp/login/send", { phoneNumber, recaptchaToken })
			.then(otpResp => {
				if (otpResp.data.results.isUser)
					return { sessionId: otpResp.data.results.data }
				else {
					toast.error("Phone number not registered")
					return { sessionId: null }
				}
			})
			.catch(e => {
				console.error(e.response.data.results.data.error)
				toast.error("Error sending OTP")
				return { sessionId: null }
			})
	},

	getRegOtp({ phoneNumber, recaptchaToken }) {
		return api
			.post("/v2/otp/send", {
				phoneNumber,
				recaptchaToken,
			})
			.then(otpResp => {
				if (otpResp.data.results.data)
					return { sessionId: otpResp.data.results.data }
				else {
					return { sessionId: null }
				}
			})
			.catch(e => {
				console.error(e.response.data.results.data.error)
				toast.error("Error sending OTP")
				return { sessionId: null }
			})
	},

	verifyOtp({ verificationCode, phoneSessionId }) {
		return api
			.post("/v2/otp/login/verify", { verificationCode, phoneSessionId })
			.then(tokenResp => {
				tokenResp = tokenResp.data.results.data
				if (tokenResp.isVerified && tokenResp.token) {
					CookieService.setTokens({
						accessToken: tokenResp.token,
						refreshToken: tokenResp.refresh,
					})
					return {
						accessToken: tokenResp.token,
						refreshToken: tokenResp.refresh,
					}
				} else if (!tokenResp.isVerified) {
					toast.error("OTP verification failed")
					return null
				}
			})
			.catch(e => {
				console.error(e)
				toast.error("Error logging in")
				return null
			})
	},

	verifyRegOtp({ verificationCode, phoneSessionId }) {
		return api
			.post("/v2/otp/verify", {
				verificationCode,
				phoneSessionId,
			})
			.catch(e => {
				console.error(e)
				toast.error("Error logging in")
				return null
			})
	},

	googleLogin({ uid, photoUrl }) {
		return api
			.post("/v2/users/google", { uid, photoUrl })
			.then(tokenResp => {
				// console.debug(tokenResp.data)
				if (tokenResp.data.token) {
					CookieService.setTokens({
						accessToken: tokenResp.data.token,
						refreshToken: tokenResp.data.refresh,
					})
					return {
						accessToken: tokenResp.data.token,
						refreshToken: tokenResp.data.refresh,
					}
				}
			})
			.catch(e => {
				console.error(e)
				toast.error("Error logging in")
				return null
			})
	},

	getProfile() {
		return api
			.get("/api/auth/user")
			.then(response => {
				if (response.data) {
					// console.debug(response.data.user)
					return { user: response.data.user }
				}
			})
			.catch(e => {
				console.error(e)
				toast.error("Error getting profile")
				return null
			})
	},

	register(data) {
		return api
			.post("/v2/trainers", data)
			.then(response => {
				if (response.data) {
					// console.debug(response.data.user)
					return { user: response.data.user }
				}
			})
			.catch(e => {
				console.error(e)
				toast.error("Error registering")
				return null
			})
	},

	updateProfile({ data, _id }) {
		return api
			.put(`/v1/trainers/profile/update/${_id}`, data)
			.then(response => {
				if (response.data) {
					// console.debug(response.data.user)
					return { user: response.data.user }
				}
			})
			.catch(e => {
				console.error(e)
				toast.error("Error updating profile")
				return null
			})
	},
}

export default AuthService
