import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/outline";
import { Fragment, useState } from "react";
import { toast } from "react-toastify";
import { useRecoilValue } from "recoil";
import { userState } from "recoil/authState";
import AuthService from "services/auth.service";
import countryCodes from "utils/countryCodes";

const Basic = () => {
	const [selected, setSelected] = useState(countryCodes[0]);
	const user = useRecoilValue(userState);
	const [loading, setLoading] = useState(false);
	const [basicProfile, setBasicProfile] = useState({
		name: user?.name || "Ankit Chaudhari",
		mobile: { countryCode: "+91", phoneNumber: "9890054712" },
		email: user?.email || "ankitchaudhari1598@gmail.com",
		password: "Ankit@1598",
		dob: new Date(user?.dob || "2000-01-01"),
		bio: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Explicabo saepe veniam quo veritatis placeat modi fugit ipsam, ad facilis distinctio quis eum necessitatibus delectus illo neque! Ipsum vitae sed ullam.",
		// photo:
		// 	user?.profileImageUrl ||
		// 	"https://drive.google.com/open?id=1dA4D4Bys7SDm3SlghEeYrXVG6xAwi9EX",
		// coverPhoto:
		// 	user?.coverImageUrl ||
		// 	"https://drive.google.com/open?id=1dA4D4Bys7SDm3SlghEeYrXVG6xAwi9EX",
		address: {
			line1: user?.address?.line1 || "line1",
			line2: user?.address?.line2 || "line2",
			line3: user?.address?.line3 || "line3",
			town: user?.address?.town || "town",
			city: user?.address?.city || "city",
			state: user?.address?.state || "state",
			pincode: user?.address?.pincode || "pincode",
		},
	});

	const handleProfileUpdate = (e) => {
		e.preventDefault();
		setLoading(true);
		AuthService.updateProfile({ data: basicProfile, _id: user?._id })
			.then(() => {
				setLoading(false);
				toast.success("Profile Updated");
			})
			.catch((err) => {
				setLoading(false);
				toast.error(err.message);
			});
	};

	return (
		<>
			<div className='relative bg-gray-50 md:p-12 py-12 px-8 rounded-xl overflow-hidden'>
				<img
					src='/logo-watermark.png'
					alt='watermark'
					className='absolute z-0 inset-0 opacity-10 md:h-[25rem] h-80 md:translate-x-[-70px] md:translate-y-[-80px] translate-x-[-55px] translate-y-[-50px]'
				/>
				<div className='relative z-10 md:grid md:grid-cols-3 md:gap-6'>
					<div className='md:col-span-12'>
						<div className='px-4 space-y-3 sm:px-6'>
							<h3 className='md:text-3xl text-2xl font-bold leading-6 text-gray-900'>
								Your Profile
							</h3>
							<p className='mt-1 font-normal text-gray-500'>
								This information will be displayed publicly so be careful what you
								share.
							</p>
						</div>
					</div>
					<div className='mt-5 md:mt-0 md:col-span-12'>
						<form onSubmit={handleProfileUpdate}>
							<div className='shadow sm:rounded-md sm:overflow-hidden'>
								<div className='px-4 py-5 bg-white space-y-6 sm:p-6'>
									<div className='grid grid-cols-6 gap-6'>
										<div className='col-span-6 sm:col-span-3'>
											<label
												htmlFor='name'
												className='block text-sm font-medium text-gray-700'
											>
												Name
											</label>
											<input
												type='text'
												name='name'
												id='name'
												className='mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
												value={basicProfile.name}
												onChange={(e) =>
													setBasicProfile((prev) => ({
														...prev,
														name: e.target.value,
													}))
												}
											/>
										</div>

										<div className='col-span-6 sm:col-span-3'>
											<label
												htmlFor='email'
												className='block text-sm font-medium text-gray-700'
											>
												Email address
											</label>
											<input
												type='text'
												name='email'
												id='email'
												className='mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
												value={basicProfile.email}
												onChange={(e) =>
													setBasicProfile((prev) => ({
														...prev,
														email: e.target.value,
													}))
												}
											/>
										</div>

										<div className='col-span-6 sm:col-span-3'>
											<label
												htmlFor='dob'
												className='block text-sm font-medium text-gray-700'
											>
												Date of Birth
											</label>
											<input
												type='date'
												name='dob'
												id='dob'
												className='mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
												valueasdate={basicProfile.dob}
												onChange={(e) =>
													setBasicProfile((prev) => ({
														...prev,
														dob: e.target.value,
													}))
												}
											/>
										</div>

										<div className='col-span-6 sm:col-span-3'>
											<label className='text-sm font-normal text-gray-500 tracking-wide'>
												Phone Number
											</label>
											<div className='w-full flex space-x-3'>
												<Listbox
													value={selected}
													onChange={(e) => {
														setSelected(e);
														setBasicProfile((prev) => ({
															...prev,
															mobile: {
																...prev.mobile,
																countryCode: e.dial_code,
															},
														}));
													}}
												>
													<div className='relative mt-1 w-max'>
														<Listbox.Button className='relative w-full py-2 pl-3 pr-10 text-left bg-white rounded-lg shadow cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-sm'>
															<span className='block truncate'>
																{selected.flag} {selected.dial_code}
															</span>
															<span className='absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none'>
																<SelectorIcon
																	className='w-5 h-5 text-gray-400'
																	aria-hidden='true'
																/>
															</span>
														</Listbox.Button>
														<Transition
															as={Fragment}
															leave='transition ease-in duration-100'
															leaveFrom='opacity-100'
															leaveTo='opacity-0'
														>
															<Listbox.Options className='absolute w-60 py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-50'>
																{countryCodes.map(
																	(country, countryIdx) => (
																		<Listbox.Option
																			key={countryIdx}
																			className={({
																				active,
																			}) =>
																				`${
																					active
																						? "text-amber-900 bg-amber-100"
																						: "text-gray-900"
																				}
																	cursor-default select-none relative py-2 pl-10 pr-4`
																			}
																			value={country}
																		>
																			{({
																				selected,
																				active,
																			}) => (
																				<>
																					<span
																						className={`${
																							selected
																								? "font-medium"
																								: "font-normal"
																						} block truncate`}
																					>
																						{
																							country.flag
																						}{" "}
																						{
																							country.name
																						}{" "}
																						(
																						{
																							country.dial_code
																						}
																						)
																					</span>
																					{selected ? (
																						<span
																							className={`${
																								active
																									? "text-amber-600"
																									: "text-amber-600"
																							}
																				absolute inset-y-0 left-0 flex items-center pl-3`}
																						>
																							<CheckIcon
																								className='w-5 h-5'
																								aria-hidden='true'
																							/>
																						</span>
																					) : null}
																				</>
																			)}
																		</Listbox.Option>
																	)
																)}
															</Listbox.Options>
														</Transition>
													</div>
												</Listbox>
												<input
													className='flex-1 text-base px-4 py-2 border-b border-gray-300 focus:outline-none rounded focus:border-lu-500'
													placeholder='mail@domain.com'
													value={basicProfile.mobile.phoneNumber}
													required
													onChange={(e) =>
														setBasicProfile((prev) => ({
															...prev,
															mobile: {
																...prev.mobile,
																phoneNumber: e.target.value,
															},
														}))
													}
												/>
											</div>
										</div>

										<div className='col-span-6'>
											<label
												htmlFor='line1'
												className='block text-sm font-medium text-gray-700'
											>
												Street address Line 1
											</label>
											<textarea
												id='line1'
												name='line1'
												rows={1}
												className='shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md'
												value={basicProfile.address.line1}
												onChange={(e) =>
													setBasicProfile((prev) => ({
														...prev,
														address: {
															...prev.address,
															line1: e.target.value,
														},
													}))
												}
											/>
										</div>
										<div className='col-span-6'>
											<label
												htmlFor='line2'
												className='block text-sm font-medium text-gray-700'
											>
												Street address Line 2
											</label>
											<textarea
												id='line2'
												name='line2'
												rows={1}
												className='shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md'
												value={basicProfile.address.line2}
												onChange={(e) =>
													setBasicProfile((prev) => ({
														...prev,
														address: {
															...prev.address,
															line2: e.target.value,
														},
													}))
												}
											/>
										</div>
										<div className='col-span-6'>
											<label
												htmlFor='line3'
												className='block text-sm font-medium text-gray-700'
											>
												Street address Line 3
											</label>
											<textarea
												id='line3'
												name='line3'
												rows={1}
												className='shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md'
												value={basicProfile.address.line3}
												onChange={(e) =>
													setBasicProfile((prev) => ({
														...prev,
														address: {
															...prev.address,
															line3: e.target.value,
														},
													}))
												}
											/>
										</div>

										<div className='col-span-6 sm:col-span-3'>
											<label
												htmlFor='pincode'
												className='block text-sm font-medium text-gray-700'
											>
												ZIP / Postal code
											</label>
											<input
												type='text'
												name='pincode'
												id='pincode'
												className='mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
												value={basicProfile.address.pincode}
												onChange={(prev) => ({
													...prev,
													address: {
														...prev.address,
														pincode: e.target.value,
													},
												})}
											/>
										</div>

										<div className='col-span-6 sm:col-span-3'>
											<label
												htmlFor='city'
												className='block text-sm font-medium text-gray-700'
											>
												City
											</label>
											<input
												type='text'
												name='city'
												id='city'
												className='mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
												value={basicProfile.address.city}
												onChange={(e) =>
													setBasicProfile((prev) => ({
														...prev,
														address: {
															...prev.address,
															city: e.target.value,
														},
													}))
												}
											/>
										</div>

										<div className='col-span-6 sm:col-span-3'>
											<label
												htmlFor='state'
												className='block text-sm font-medium text-gray-700'
											>
												State
											</label>
											<input
												type='text'
												name='state'
												id='state'
												className='mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
												value={basicProfile.address.state}
												onChange={(e) =>
													setBasicProfile((prev) => ({
														...prev,
														address: {
															...prev.address,
															state: e.target.value,
														},
													}))
												}
											/>
										</div>

										<div className='col-span-6 sm:col-span-3'>
											<label
												htmlFor='country'
												className='block text-sm font-medium text-gray-700'
											>
												Country
											</label>
											<Listbox
												value={selected}
												onChange={(e) => {
													setSelected(e);
													setBasicProfile((prev) => ({
														...prev,
														address: {
															...prev.address,
															country: e.value,
														},
													}));
												}}
											>
												<div className='relative mt-1'>
													<Listbox.Button className='relative w-full py-2 pl-3 pr-10 text-left bg-white rounded-lg shadow cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-sm'>
														<span className='block truncate'>
															{selected.flag} {selected.name}
														</span>
														<span className='absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none'>
															<SelectorIcon
																className='w-5 h-5 text-gray-400'
																aria-hidden='true'
															/>
														</span>
													</Listbox.Button>
													<Transition
														as={Fragment}
														leave='transition ease-in duration-100'
														leaveFrom='opacity-100'
														leaveTo='opacity-0'
													>
														<Listbox.Options className='absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-50'>
															{countryCodes.map(
																(country, countryIdx) => (
																	<Listbox.Option
																		key={countryIdx}
																		className={({ active }) =>
																			`${
																				active
																					? "text-amber-900 bg-amber-100"
																					: "text-gray-900"
																			}
																	cursor-default select-none relative py-2 pl-10 pr-4`
																		}
																		value={country}
																	>
																		{({ selected, active }) => (
																			<>
																				<span
																					className={`${
																						selected
																							? "font-medium"
																							: "font-normal"
																					} block truncate`}
																				>
																					{country.flag}{" "}
																					{country.name}
																				</span>
																				{selected ? (
																					<span
																						className={`${
																							active
																								? "text-amber-600"
																								: "text-amber-600"
																						}
																				absolute inset-y-0 left-0 flex items-center pl-3`}
																					>
																						<CheckIcon
																							className='w-5 h-5'
																							aria-hidden='true'
																						/>
																					</span>
																				) : null}
																			</>
																		)}
																	</Listbox.Option>
																)
															)}
														</Listbox.Options>
													</Transition>
												</div>
											</Listbox>
										</div>
									</div>

									<div>
										<label
											htmlFor='about'
											className='block text-sm font-medium text-gray-700'
										>
											About
										</label>
										<div className='mt-1'>
											<textarea
												id='about'
												name='about'
												rows={3}
												className='shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md'
												value={basicProfile.bio}
												onChange={(prev) => ({
													...prev,
													bio: e.target.value,
												})}
											/>
										</div>
										<p className='mt-2 text-sm text-gray-500'>
											Brief description for your profile.
											{/* URLs are hyperlinked. */}
										</p>
									</div>

									{/* <div>
										<label className='block text-sm font-medium text-gray-700'>
											Photo
										</label>
										<div className='mt-1 flex items-center'>
											<span className='inline-block h-12 w-12 rounded-full overflow-hidden bg-gray-100'>
												<svg
													className='h-full w-full text-gray-300'
													fill='currentColor'
													viewBox='0 0 24 24'
												>
													<path d='M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z' />
												</svg>
											</span>
											<button
												type='button'
												className='ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
											>
												Change
											</button>
										</div>
									</div>

									<div>
										<label className='block text-sm font-medium text-gray-700'>
											Cover photo
										</label>
										<div className='mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md'>
											<div className='space-y-1 text-center'>
												<svg
													className='mx-auto h-12 w-12 text-gray-400'
													stroke='currentColor'
													fill='none'
													viewBox='0 0 48 48'
													aria-hidden='true'
												>
													<path
														d='M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02'
														strokeWidth={2}
														strokeLinecap='round'
														strokeLinejoin='round'
													/>
												</svg>
												<div className='flex text-sm text-gray-600'>
													<label
														htmlFor='file-upload'
														className='relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500'
													>
														<span>Upload a file</span>
														<input
															id='file-upload'
															name='file-upload'
															type='file'
															className='sr-only'
														/>
													</label>
													<p className='pl-1'>or drag and drop</p>
												</div>
												<p className='text-xs text-gray-500'>
													PNG, JPG, GIF up to 10MB
												</p>
											</div>
										</div>
									</div> */}
								</div>
								<div className='px-4 py-3 bg-gray-50 text-right sm:px-6 border-t border-gray-200'>
									<button
										type='submit'
										className='inline-flex justify-center border border-transparent text-sm focus:outline-none w-max bg-black text-gray-100 px-8 py-3 rounded-full tracking-wide font-semibold shadow-lg cursor-pointer'
									>
										Save
									</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</>
	);
};

export default Basic;
