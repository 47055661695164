const urlify = text => {
	let urls = []
	var urlRegex = /(https?:\/\/[^\s]+)/g
	// let mentionRegex = /\@(.*?)\)/gim;
	// let mentionNameRegex = /(?<=\[)[^\]\[\r\n]*(?=\])/gim;
	// let mentionUsernameRegex = /(?<=\()[^\]\[\r\n]*(?=\))/gim;
	// var mentionText = text.replace(mentionRegex, function (mention) {
	// 	var name1;
	// 	var username1;
	// 	mention.replace(mentionNameRegex, function (name) {
	// 		name1 = name;
	// 	});
	// 	mention.replace(mentionUsernameRegex, function (username) {
	// 		username1 = username;
	// 	});
	// 	return `<a className="text-blue-500 hover:underline" target="_blank" href="/user/${username1}"> @${name1} </a>`;
	// });
	return {
		text: text
			? text.replace(urlRegex, function (url) {
					urls.push(url)
					return `<a class="text-blue-500 hover:underline" target="_blank" href="${url}">${url}</a>`
			  })
			: "",
		urls,
	}
}

export default urlify
