import classNames from "utils/classNames"
import packageJson from "../../package.json"
import { useRouter } from "next/router"

const Footer = () => {
	const router = useRouter()
	return (
		<>
			<div
				className={classNames(
					"flex items-center justify-center px-4 py-2",
					router.pathname === "/"
						? "bgSquareDark bg-black"
						: "bg-gray-50"
				)}
			>
				<span className="text-xs text-gray-300">
					v{packageJson.version}
				</span>
			</div>
		</>
	)
}

export default Footer
