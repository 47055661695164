import { DownloadIcon } from "@heroicons/react/outline"
import React from "react"
import { defaultStyles, FileIcon } from "react-file-icon"

const File = ({ resource }) => {
	return (
		<>
			<div className="flex h-full w-full flex-col justify-center space-y-2">
				<div className="flex flex-col items-center space-x-2 space-y-2">
					<div className="flex w-6 min-w-[24px] content-center justify-center">
						<FileIcon
							extension={resource.ext}
							{...defaultStyles[resource.ext]}
						/>
					</div>
					<p className="overflow-anywhere self-center text-sm">
						{resource.name ||
							resource.link.split("/").at(-1).split("_").at(-1)}
					</p>
				</div>
				{resource.text && (
					<p
						dangerouslySetInnerHTML={{
							__html: resource.text,
						}}
						className="overflow-anywhere text-sm"
					/>
				)}
			</div>
		</>
	)
}

export default File
