import { Analytics } from "@vercel/analytics/react"
import { Footer, Loading, Navbar, WPBtn } from "components"
import Head from "next/head"
import { useRouter } from "next/router"
import Script from "next/script"
import "quill/dist/quill.core.css"
import { useEffect } from "react"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import {
	RecoilRoot,
	useRecoilState,
	useRecoilValue,
	useSetRecoilState,
} from "recoil"
import { loadingState, userState } from "recoil/authState"
import AuthService from "services/auth.service"
import CookieService from "services/cookie.service"
import "styles/globals.css"
import classNames from "utils/classNames"

const App = ({ Component, pageProps }) => {
	const router = useRouter()
	const setUser = useSetRecoilState(userState)
	const [loading, setLoading] = useRecoilState(loadingState)

	const setLuckyOrangeIdentity = userProfile => {
		const user = {
			name: userProfile.name,
			profileImageUrl: userProfile.profileImageUrl,
			uid: userProfile.uid,
			username: userProfile.username,
			email: userProfile.email,
		}
		window.LOQ = window.LOQ || []
		window.LOQ.push([
			"ready",
			async LO => {
				// Or, identify a visitor
				await LO.$internal.ready("visitor")
				LO.visitor.identify(user)
			},
		])
	}

	useEffect(() => {
		const checkAutoLogin = () => {
			AuthService.getProfile().then(resp => {
				setUser(resp.user)
				setLuckyOrangeIdentity(resp.user)
				setLoading(false)
			})
		}
		if (CookieService.getLocalAccessToken()) {
			setLoading(true)
			checkAutoLogin()
		} else {
			setLoading(false)
		}
	}, [])

	if (loading) return <Loading />

	return (
		<>
			{router.pathname !== "/login" ? (
				<header>
					<Navbar />
				</header>
			) : null}
			<main
				className={classNames(
					"relative min-h-footerScreen select-text",
					router.pathname === "/"
						? "bgSquareDark bg-black"
						: "bg-gray-50"
				)}
			>
				{Component.auth ? (
					<Auth>
						<Component {...pageProps} />
					</Auth>
				) : (
					<Component {...pageProps} />
				)}
				<WPBtn />
			</main>
			{!["/login", "/register"].includes(router.pathname) ? (
				<footer>
					<Footer />
				</footer>
			) : null}
		</>
	)
}

const Auth = ({ children }) => {
	const user = useRecoilValue(userState)
	const router = useRouter()

	useEffect(() => {
		if (router.pathname === "/console" && !user) {
			router.replace("/")
			return
		}
		if (router.pathname !== "/login") {
			if (!user && !CookieService.getLocalAccessToken()) {
				// router.push(`/login?r=${encodeURI(window.location.pathname)}`);
				router.push(`/login`)
			}
		} else {
			if (user) {
				router.push("/console")
			}
		}
	}, [user])

	if (user) return children

	return <Loading />
}

const RecoilApp = props => {
	useEffect(() => {
		const check = () => {
			let vh = window.innerHeight * 0.01
			document.documentElement.style.setProperty("--vh", `${vh}px`)
		}
		check()
		window.addEventListener("resize", check)
		return () => window.removeEventListener("resize", check)
	}, [])

	return (
		<RecoilRoot>
			<Head>
				<link
					rel="stylesheet"
					href="https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/css/intlTelInput.css"
				/>
			</Head>
			<Script
				src="https://tools.luckyorange.com/core/lo.js?site-id=63c10d6c"
				strategy="beforeInteractive"
			/>
			<Script
				strategy="beforeInteractive"
				dangerouslySetInnerHTML={{
					__html: `
(function(c,l,a,r,i,t,y){
	c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
	t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
	y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
})(window, document, "clarity", "script", "f7vckn8ams");
`,
				}}
			/>
			<Script
				strategy="beforeInteractive"
				src="https://www.googletagmanager.com/gtag/js?id=G-ECYVNNYDQ3"
			/>
			<Script
				strategy="beforeInteractive"
				dangerouslySetInnerHTML={{
					__html: `
	window.dataLayer = window.dataLayer || [];
	function gtag(){dataLayer.push(arguments);}
	gtag('js', new Date());
	gtag('config', 'G-ECYVNNYDQ3');
`,
				}}
			/>
			<Script
				src="https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/intlTelInput.min.js"
				strategy="beforeInteractive"
			/>
			<App {...props} />
			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop
				closeOnClick
				rtl={false}
				pauseOnFocusLoss={false}
				draggable
				theme="colored"
				pauseOnHover
				limit={3}
			/>
			<Analytics debug={process.env.NODE_ENV !== "development"} />
		</RecoilRoot>
	)
}

export default RecoilApp
