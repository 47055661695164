import Head from "next/head"

const ConsoleSeo = () => {
	return (
		<Head>
			<title>LU Trainer Console</title>
			<link
				rel="icon"
				href="/favicon.ico"
			/>
		</Head>
	)
}

export default ConsoleSeo
