import Head from "next/head"

const ClassSeo = ({ name }) => {
	return (
		<Head>
			{name ? (
				<title>LU Trainer Console | {name}</title>
			) : (
				<title>LU Trainer Console</title>
			)}
		</Head>
	)
}

export default ClassSeo
