import { PencilIcon, TrashIcon } from "@heroicons/react/outline"
import { useState } from "react"
import { toast } from "react-toastify"
import { useRecoilValue } from "recoil"
import { agendaListState } from "recoil/statsState"
import classNames from "utils/classNames"
import AgendaForm from "./AgendaForm"

const Agenda = ({ completeAgenda, removeAgenda, updateAgenda }) => {
	const agendas = useRecoilValue(agendaListState)
	const [edit, setEdit] = useState({
		id: null,
		text: "",
	})

	const submitUpdate = value => {
		updateAgenda(value)
		setEdit({
			id: null,
			value: "",
		})
	}

	return (
		<ul className="divide-y divide-gray-200 text-sm font-medium text-gray-500">
			{agendas.length ? (
				agendas?.map((agenda, idx) =>
					agenda.id === edit.id ? (
						<AgendaForm
							edit={edit}
							onSubmit={submitUpdate}
						/>
					) : (
						<li
							key={agenda.id}
							className={classNames(
								"flex select-none bg-white py-4 text-black hover:bg-gray-50",
								agenda.isCompleted ? "opacity-40" : ""
							)}
						>
							<span
								className="flex flex-1 cursor-pointer select-text space-x-2"
								onClick={() =>
									completeAgenda({
										...agenda,
										isCompleted: !agenda.isCompleted,
									})
								}
							>
								<div
									className={classNames(
										agenda.isCompleted ? "text-black" : ""
									)}
								>
									{idx + 1}.
								</div>
								<div
									className={classNames(
										"flex-1",
										agenda.isCompleted
											? "text-black line-through decoration-black"
											: ""
									)}
								>
									{agenda.text}
								</div>
							</span>
							<div className="mx-1 flex cursor-pointer items-center space-x-4 text-2xl">
								<button
									type="button"
									className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-0.5 text-sm font-medium leading-5 text-gray-700 shadow-sm hover:bg-gray-50"
									onClick={() =>
										agenda.isCompleted
											? toast.warning(
													"Cannot Edit Completed Agenda"
											  )
											: setEdit({
													id: agenda.id,
													value: agenda.text,
											  })
									}
								>
									Edit
								</button>
								<button
									type="button"
									className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-0.5 text-sm font-medium leading-5 text-gray-700 shadow-sm hover:bg-gray-50"
								>
									<TrashIcon
										className="h-5 w-5 text-red-500"
										onClick={() => removeAgenda(agenda.id)}
									/>
								</button>
							</div>
							{/* <div className="mx-1 flex cursor-pointer items-center space-x-4 text-2xl">
								<PencilIcon
									className="h-6 w-6 text-slate-500 hover:scale-110"
									onClick={() =>
										agenda.isCompleted
											? toast.warning(
													"Cannot Edit Completed Agenda"
											  )
											: setEdit({
													id: agenda.id,
													value: agenda.text,
											  })
									}
								/>
								<TrashIcon
									className="h-6 w-6 text-red-500 hover:scale-110"
									onClick={() => removeAgenda(agenda.id)}
								/>
							</div> */}
						</li>
					)
				)
			) : (
				<div className="flex h-full select-none flex-col items-center justify-center space-x-1 space-y-8 text-black">
					<img
						src="/noAgenda.png"
						alt="No Agenda's Listed"
						width="200px"
					/>
					<span className="text-gray-500">No Agenda's Listed</span>
				</div>
			)}
		</ul>
	)
}

export default Agenda
