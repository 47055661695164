import {
	ConsoleSeo,
	ClassSeo,
	ClassesSeo,
	HomeSeo,
	LoginSeo,
	RegisterSeo,
} from "components/SEO"
import { useRouter } from "next/router"
import classNames from "utils/classNames"

const SEO = {
	"/classes/[class]": <ClassSeo />,
	"/classes": <ClassesSeo />,
	"/": <HomeSeo />,
	"/login": <LoginSeo />,
	"/register": <RegisterSeo />,
	"/console": <ConsoleSeo />,
}

const Loading = ({ nav = false }) => {
	const { pathname } = useRouter()

	return (
		<>
			{SEO[pathname] ? SEO[pathname] : <HomeSeo />}
			<div
				className={classNames(
					"flex items-center justify-center",
					nav ? "mt-16 min-h-navScreen" : "h-screen"
				)}
			>
				<svg
					fill="none"
					viewBox="0 0 24 24"
					className="h-8 w-8 animate-spin"
				>
					<circle
						className="opacity-25"
						cx="12"
						cy="12"
						r="10"
						stroke="#ff66005e"
						strokeWidth="4"
					/>
					<path
						fill="#ff6600"
						d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
					/>
				</svg>
			</div>
		</>
	)
}

export default Loading
