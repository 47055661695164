import { CheckIcon, PlusIcon } from "@heroicons/react/solid"
import { nanoid } from "nanoid"
import { useState } from "react"
import classNames from "utils/classNames"

const AgendaForm = ({ onSubmit, edit }) => {
	const [input, setInput] = useState(edit ? edit.value : "")
	const [placeholder] = useState(
		edit ? edit.value : "Describe the agenda for this class"
	)

	const handleSubmit = e => {
		e?.preventDefault()
		onSubmit({
			id: edit ? edit.id : nanoid(16),
			text: input,
			isCompleted: false,
		})
		setInput("")
	}

	return (
		<>
			<form
				onSubmit={handleSubmit}
				className={classNames(
					"relative flex space-x-4",
					edit ? "mb-4 pt-4" : ""
				)}
			>
				<input
					className="block w-full rounded-md border-gray-300 shadow-sm focus:border-lu-500 focus:ring-lu-500 sm:text-sm"
					type="text"
					value={input}
					placeholder={
						placeholder || "Describe the agenda for this class"
					}
					name="text"
					onChange={e => {
						setInput(e.target.value)
					}}
				/>
				<button
					type="submit"
					disabled={!input}
					className="flex items-center space-x-2 rounded-md border border-l-0 border-gray-300 bg-black px-8 text-sm font-medium text-gray-500 disabled:opacity-50"
				>
					{edit ? (
						<span className="text-white">Update</span>
					) : (
						<span className="text-white">Add</span>
					)}
				</button>
			</form>
		</>
	)
}

export default AgendaForm
