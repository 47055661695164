import { Disclosure, Menu, Transition } from "@headlessui/react"
import { LogoutIcon, MenuIcon } from "@heroicons/react/outline"
import { ChevronDownIcon, XIcon } from "@heroicons/react/solid"
import Link from "next/link"
import { useRouter } from "next/router"
import { Fragment, useEffect, useState } from "react"
import { useRecoilValue, useResetRecoilState } from "recoil"
import { tokenState, userState } from "recoil/authState"
import CookieService from "services/cookie.service"
import classNames from "utils/classNames"

const NavLink = ({ href, exact, activeClassName, children, ...props }) => {
	const { pathname } = useRouter()
	const isActive = exact ? pathname === href : pathname.startsWith(href)

	if (isActive) {
		props.className += ` ${activeClassName}`
	}

	return (
		<Link href={href}>
			<a {...props}>{children}</a>
		</Link>
	)
}

const Navbar = () => {
	const user = useRecoilValue(userState)
	const resetUser = useResetRecoilState(userState)
	const resetTokens = useResetRecoilState(tokenState)
	const router = useRouter()

	const handleSignOut = () => {
		resetTokens()
		resetUser()
		CookieService.removeTokens()
		router.push("/")
	}

	return (
		<>
			<nav className="relative">
				<Disclosure
					as="nav"
					className={classNames(
						"fixed inset-x-0 top-0 z-50 shadow-[0_1px_3px_rgba(15,23,42,0.08)]",
						router.pathname === "/"
							? "bgSquareDark bg-black"
							: "bg-gray-50"
					)}
				>
					{({ open }) => (
						<>
							<div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
								<div className="flex h-16 items-center justify-between">
									<div className="flex items-center justify-center sm:items-stretch sm:justify-start">
										<NavLink
											exact
											activeClassName="text-lu-500"
											href={user ? "/console" : "/"}
											className="flex flex-shrink-0 items-center"
										>
											{router.pathname === "/" ? (
												<img
													className="h-12 w-auto md:h-14"
													src="https://lucdn.letsupgrade.net/lu_White_0f8c91d7a1.png"
													alt="lu"
												/>
											) : (
												<img
													className="h-12 w-auto md:h-14"
													src="https://lucdn.letsupgrade.net/lu_Black_f2328beac0.png"
													alt="lu"
												/>
											)}
										</NavLink>
									</div>
									<div className="hidden sm:ml-6 sm:block">
										<div className="flex space-x-4">
											<div className="flex items-center justify-around space-x-5">
												{user ? (
													<>
														<Menu
															as="div"
															className="relative inline-block text-left"
														>
															<Menu.Button className="flex h-10 cursor-pointer items-center justify-center rounded border-0 py-1 px-2 text-black focus:outline-none">
																{/* {user?.name} */}
																<span className="sr-only">
																	Open user
																	menu
																</span>
																<img
																	className="h-8 w-8 rounded-md"
																	src={
																		user.profileImageUrl ||
																		`https://avatars.dicebear.com/api/initials/${user.name}.svg`
																	}
																	alt=""
																/>
																{/* <ChevronDownIcon
																className="text-blak ml-2 -mr-1 h-5 w-5"
																aria-hidden="true"
															/> */}
															</Menu.Button>
															<Transition
																as={Fragment}
																enter="transition ease-out duration-100"
																enterFrom="transform opacity-0 scale-95"
																enterTo="transform opacity-100 scale-100"
																leave="transition ease-in duration-75"
																leaveFrom="transform opacity-100 scale-100"
																leaveTo="transform opacity-0 scale-95"
															>
																<Menu.Items className="absolute right-0 mt-2 w-48 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
																	<div className="px-1 py-1 ">
																		<Menu.Item>
																			{({
																				active,
																			}) => (
																				<button
																					className={`${
																						active
																							? "bg-lu-500 text-white"
																							: "text-gray-900"
																					} group flex w-full items-center rounded-md px-2 py-2 text-sm`}
																					onClick={
																						handleSignOut
																					}
																				>
																					<LogoutIcon
																						className="mr-2 h-5 w-5"
																						aria-hidden="true"
																					/>
																					Logout
																				</button>
																			)}
																		</Menu.Item>
																	</div>
																</Menu.Items>
															</Transition>
														</Menu>
													</>
												) : (
													<Link href="/login">
														<a
															className={classNames(
																router.pathname !==
																	"/register"
																	? "block w-full rounded-md border border-transparent bg-white px-4 py-2 text-center text-base font-medium text-lu-500 shadow-md hover:bg-gray-50 active:shadow-sm sm:inline-block sm:w-auto"
																	: "inline-flex w-full items-center rounded-md border border-transparent bg-lu-500 px-4 py-2 font-medium text-white shadow-sm"
															)}
														>
															Login
														</a>
													</Link>
												)}
											</div>
										</div>
									</div>
									<div className="inset-y-0 left-0 flex items-center sm:hidden">
										{/* Mobile menu button*/}
										{user ? (
											<img
												className="h-8 w-8 rounded-md"
												src={
													user?.profileImageUrl ||
													`https://avatars.dicebear.com/api/initials/${user.name}.svg`
												}
												alt=""
												onClick={handleSignOut}
											/>
										) : (
											<Link href="/login">
												<a
													className={classNames(
														router.pathname !==
															"/register"
															? "block w-full rounded-md border border-transparent bg-white px-4 py-2 text-center text-base font-medium text-lu-500 shadow-md hover:bg-gray-50 active:shadow-sm sm:inline-block sm:w-auto"
															: "block w-full rounded-md border border-transparent bg-lu-500 py-2 px-4 text-center text-base font-medium text-white shadow-md transition-all active:shadow-sm sm:inline-block sm:w-auto"
													)}
												>
													Login
												</a>
											</Link>
										)}
									</div>
								</div>
							</div>
						</>
					)}
				</Disclosure>
			</nav>
		</>
	)
}

export default Navbar
