import { useState } from "react";
import { toast } from "react-toastify";
import { useRecoilValue } from "recoil";
import { userState } from "recoil/authState";
import api from "services/api";
import AuthService from "services/auth.service";

const Bank = () => {
	const user = useRecoilValue(userState);
	const [loading, setLoading] = useState(false);
	const [bankProfile, setBankProfile] = useState({
		panCard: {
			number: user?.panCard?.number || "panCardNumber",
			photo:
				user?.panCard?.photo ||
				"https://drive.google.com/open?id=1dA4D4Bys7SDm3SlghEeYrXVG6xAwi9EX",
		},
		gst: {
			number: user?.gst?.number || "gstNumber",
			photo:
				user?.gst?.photo ||
				"https://drive.google.com/open?id=1dA4D4Bys7SDm3SlghEeYrXVG6xAwi9EX",
		},
		bank: {
			accNumber: user?.bank?.accNumber || "accNumber",
			ifsc: user?.bank?.ifsc || "ifsc",
			accHolderName: user?.bank?.accHolderName || "accHolderName",
			bankName: user?.bank?.bankName || "bankName",
			branchName: user?.bank?.branchName || "branchName",
			photo:
				user?.bank?.photo ||
				"https://drive.google.com/open?id=1dA4D4Bys7SDm3SlghEeYrXVG6xAwi9EX",
		},
	});
	const [files, setFiles] = useState({
		panCard: {
			photo: user?.panCard?.photo || "https://via.placeholder.com/300x100",
			preview: user?.panCard?.photo || "https://via.placeholder.com/300x100",
		},
		gst: {
			photo: user?.gst?.photo || "https://via.placeholder.com/300x100",
			preview: user?.panCard?.photo || "https://via.placeholder.com/300x100",
		},
		bank: {
			photo:
				user?.bank?.photo || "https://via.placeholder.com/300x100",
			preview: user?.panCard?.photo || "https://via.placeholder.com/300x100",
		},
	});

	const handleFileChange = (e, type) => {
		const file = e.target.files[0];
		const formData = new FormData();
		formData.append("image", file);
		api.post(`/v1/trainers/media/upload?type=${type}`, formData, {
			headers: {
				"Content-Type": "multipart/form-data",
			},
		})
			.then((res) => {
				setFiles((prevState) => ({
					...prevState,
					[type]: {
						...prevState[type],
						preview: URL.createObjectURL(file),
					},
				}));
				setBankProfile((prevState) => ({
					...prevState,
					[type]: {
						...prevState[type],
						photo: res.data.results.url,
					},
				}));
			})
			.catch((err) => {
				console.error(err);
				toast.error("Error uploading file");
			});
	};

	const handleProfileUpdate = (e) => {
		e.preventDefault();
		setLoading(true);
		AuthService.updateProfile({ data: bankProfile, _id: user?._id })
			.then(() => {
				setLoading(false);
				toast.success("Profile Updated");
			})
			.catch((err) => {
				setLoading(false);
				toast.error(err.message);
			});
	};

	return (
		<>
			<div className='bg-gray-50 md:p-12 py-12 px-8 rounded-xl'>
				<div className='md:grid md:grid-cols-3 md:gap-6'>
					<div className='md:col-span-12'>
						<div className='px-4 space-y-3 sm:px-6'>
							<h3 className='md:text-3xl text-2xl font-bold leading-6 text-gray-900'>
								Bank Information
							</h3>
							<p className='mt-1 font-normal text-gray-500'>
								Use a permanent address where you can receive mail.
							</p>
						</div>
					</div>
					<div className='mt-5 md:mt-0 md:col-span-12'>
						<form onSubmit={handleProfileUpdate}>
							<div className='shadow overflow-hidden sm:rounded-md'>
								<div className='px-4 py-5 bg-white sm:p-6 space-y-6'>
									<fieldset className='w-full border border-solid border-gray-300 p-3 rounded-md'>
										<legend className='text-sm text-gray-600'>
											Pan Details
										</legend>
										<div className='grid grid-cols-6 gap-6'>
											<div className='col-span-6 sm:col-span-3'>
												<label
													htmlFor='panNumber'
													className='block text-sm font-medium text-gray-700'
												>
													Number
												</label>
												<input
													type='text'
													name='panNumber'
													id='panNumber'
													className='mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
													value={bankProfile.panCard.number}
													onChange={(e) =>
														setBankProfile((prev) => ({
															...prev,
															panCard: {
																...prev.panCard,
																number: e.target.value,
															},
														}))
													}
												/>
											</div>

											<div className='col-span-6 sm:col-span-3'>
												<label
													htmlFor='email-address'
													className='block text-sm font-medium text-gray-700'
												>
													Image
												</label>
												{/* <input
													type='text'
													name='email-address'
													id='email-address'
													autoComplete='email'
													className='mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
												/> */}
												<div className='relative overflow-hidden max-w-[300px] h-[100px] mt-2 group'>
													<img
														src={files.panCard.preview}
														alt='tempImg'
														className='opacity-100 group-hover:opacity-25 focus:ring-itm focus:border-itm block w-full h-full shadow-sm sm:text-sm border-gray-300 rounded-md'
													/>
													<button className='absolute inset-0 text-gray-600 font-bold w-full h-full z-0 opacity-0 group-hover:opacity-100 flex justify-center items-center'>
														{false ? "Edit" : "Upload"} Image
													</button>
													<input
														// required
														type='file'
														accept='.png, .jpg, .jpeg'
														id='eventImg'
														className='h-full w-full absolute inset-0 z-20 opacity-0 group-hover:opacity-0'
														onChange={(e) => {
															handleFileChange(e, "panCard");
															setFiles((prev) => ({
																...prev,
																panCard: {
																	...prev.panCard,
																	preview: URL.createObjectURL(
																		e.target.files[0]
																	),
																},
															}));
														}}
													/>
												</div>
											</div>
										</div>
									</fieldset>
									<fieldset className='w-full border border-solid border-gray-300 p-3 rounded-md'>
										<legend className='text-sm text-gray-600'>
											GST Details
										</legend>
										<div className='grid grid-cols-6 gap-6'>
											<div className='col-span-6 sm:col-span-3'>
												<label
													htmlFor='gstNumber'
													className='block text-sm font-medium text-gray-700'
												>
													Number
												</label>
												<input
													type='text'
													name='gstNumber'
													id='gstNumber'
													className='mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
													value={bankProfile.gst.number}
													onChange={(e) =>
														setBankProfile((prev) => ({
															...prev,
															gst: {
																...prev.gst,
																number: e.target.value,
															},
														}))
													}
												/>
											</div>

											<div className='col-span-6 sm:col-span-3'>
												<label
													htmlFor='email-address'
													className='block text-sm font-medium text-gray-700'
												>
													Image
												</label>
												{/* <input
													type='text'
													name='email-address'
													id='email-address'
													autoComplete='email'
													className='mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
												/> */}
												<div className='relative overflow-hidden max-w-[300px] h-[100px] mt-2 group'>
													<img
														src={files.gst.preview}
														alt='tempImg'
														className='opacity-100 group-hover:opacity-25 focus:ring-itm focus:border-itm block w-full h-full shadow-sm sm:text-sm border-gray-300 rounded-md'
													/>
													<button className='absolute inset-0 text-gray-600 font-bold w-full h-full z-0 opacity-0 group-hover:opacity-100 flex justify-center items-center'>
														{false ? "Edit" : "Upload"} Image
													</button>
													<input
														// required
														type='file'
														accept='.png, .jpg, .jpeg'
														id='eventImg'
														className='h-full w-full absolute inset-0 z-20 opacity-0 group-hover:opacity-0'
														onChange={(e) => {
															handleFileChange(e, "gst");
															setFiles((prev) => ({
																...prev,
																gst: {
																	...prev.gst,
																	preview: URL.createObjectURL(
																		e.target.files[0]
																	),
																},
															}));
														}}
													/>
												</div>
											</div>
										</div>
									</fieldset>
									<fieldset className='w-full border border-solid border-gray-300 p-3 rounded-md'>
										<legend className='text-sm text-gray-600'>
											Bank Details
										</legend>
										<div className='grid grid-cols-6 gap-6'>
											<div className='col-span-6 sm:col-span-3'>
												<label
													htmlFor='bankNumber'
													className='block text-sm font-medium text-gray-700'
												>
													Account Number
												</label>
												<input
													type='text'
													name='bankNumber'
													id='bankNumber'
													className='mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
													value={bankProfile.bank.accNumber}
													onChange={(e) =>
														setBankProfile((prev) => ({
															...prev,
															bank: {
																...prev.bank,
																accNumber: e.target.value,
															},
														}))
													}
												/>
											</div>
											<div className='col-span-6 sm:col-span-3'>
												<label
													htmlFor='bankIfsc'
													className='block text-sm font-medium text-gray-700'
												>
													IFSC Code
												</label>
												<input
													type='text'
													name='bankIfsc'
													id='bankIfsc'
													className='mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
													value={bankProfile.bank.ifsc}
													onChange={(e) =>
														setBankProfile((prev) => ({
															...prev,
															bank: {
																...prev.bank,
																ifsc: e.target.value,
															},
														}))
													}
												/>
											</div>
											<div className='col-span-6 sm:col-span-3'>
												<label
													htmlFor='bankAccHolderName'
													className='block text-sm font-medium text-gray-700'
												>
													Account Holder Name
												</label>
												<input
													type='text'
													name='bankAccHolderName'
													id='bankAccHolderName'
													className='mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
													value={bankProfile.bank.accHolderName}
													onChange={(e) =>
														setBankProfile((prev) => ({
															...prev,
															bank: {
																...prev.bank,
																accHolderName: e.target.value,
															},
														}))
													}
												/>
											</div>
											<div className='col-span-6 sm:col-span-3'>
												<label
													htmlFor='bankName'
													className='block text-sm font-medium text-gray-700'
												>
													Bank Name
												</label>
												<input
													type='text'
													name='bankName'
													id='bankName'
													autoComplete='given-name'
													className='mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
													value={bankProfile.bank.bankName}
													onChange={(e) =>
														setBankProfile((prev) => ({
															...prev,
															bank: {
																...prev.bank,
																bankName: e.target.value,
															},
														}))
													}
												/>
											</div>
											<div className='col-span-6 sm:col-span-3'>
												<label
													htmlFor='bankBranchName'
													className='block text-sm font-medium text-gray-700'
												>
													Branch Name
												</label>
												<input
													type='text'
													name='bankBranchName'
													id='bankBranchName'
													autoComplete='given-name'
													className='mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
													value={bankProfile.bank.branchName}
													onChange={(e) =>
														setBankProfile((prev) => ({
															...prev,
															bank: {
																...prev.bank,
																branchName: e.target.value,
															},
														}))
													}
												/>
											</div>

											<div className='col-span-6 sm:col-span-3'>
												<label
													htmlFor='email-address'
													className='block text-sm font-medium text-gray-700'
												>
													Cheque / Passbook Image
												</label>
												{/* <input
													type='text'
													name='email-address'
													id='email-address'
													autoComplete='email'
													className='mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
												/> */}
												<div className='relative overflow-hidden max-w-[300px] h-[100px] mt-2 group'>
													<img
														src={files.bank.preview}
														alt='tempImg'
														className='opacity-100 group-hover:opacity-25 focus:ring-itm focus:border-itm block w-full h-full shadow-sm sm:text-sm border-gray-300 rounded-md'
													/>
													<button className='absolute inset-0 text-gray-600 font-bold w-full h-full z-0 opacity-0 group-hover:opacity-100 flex justify-center items-center'>
														{false ? "Edit" : "Upload"} Image
													</button>
													<input
														// required
														type='file'
														accept='.png, .jpg, .jpeg'
														id='eventImg'
														className='h-full w-full absolute inset-0 z-20 opacity-0 group-hover:opacity-0'
														onChange={(e) => {
															handleFileChange(e, "bank");
															setFiles((prev) => ({
																...prev,
																bank: {
																	...prev.bank,
																	preview: URL.createObjectURL(
																		e.target.files[0]
																	),
																},
															}));
														}}
													/>
												</div>
											</div>
										</div>
									</fieldset>
								</div>
								<div className='px-4 py-3 bg-gray-50 text-right sm:px-6 border-t border-gray-200'>
									<button
										type='submit'
										className='inline-flex justify-center border border-transparent text-sm focus:outline-none w-max bg-black text-gray-100 px-8 py-3 rounded-full tracking-wide font-semibold shadow-lg cursor-pointer'
									>
										Save
									</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</>
	);
};

export default Bank;
