import Cookie from "js-cookie"

const cookieOptions = {
	domain:
		typeof window !== "undefined"
			? window.location.hostname.includes("letsupgrade")
				? ".letsupgrade.in"
				: window.location.hostname
			: "localhost",
	path: "/",
}

console.debug(cookieOptions)

const CookieService = {
	getLocalRefreshToken() {
		// return localStorage.getItem("lupower_refresh") || false;
		return Cookie.get("lupower_refresh", cookieOptions) || false
	},

	getLocalAccessToken() {
		// return localStorage.getItem("lupower") || false;
		return Cookie.get("lupower", cookieOptions) || false
	},

	updateLocalAccessToken(token) {
		// localStorage.setItem("lupower", token);
		Cookie.set("lupower", token)
	},

	setTokens({ accessToken, refreshToken }) {
		// localStorage.setItem("lupower", accessToken);
		Cookie.set("lupower", accessToken, cookieOptions)
		// localStorage.setItem("lupower_refresh", refreshToken);
		Cookie.set("lupower_refresh", refreshToken, cookieOptions)
	},

	removeTokens() {
		// localStorage.removeItem("lupower");
		Cookie.remove("lupower", cookieOptions)
		// localStorage.removeItem("lupower_refresh");
		Cookie.remove("lupower_refresh", cookieOptions)
	},
}

export default CookieService
