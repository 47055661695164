import dynamic from "next/dynamic";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { feedbackListState } from "recoil/statsState";
import formatDate from "utils/formatDate";
const ApexChartNoSSRWrapper = dynamic(import("react-apexcharts"), {
	ssr: false,
	loading: () => <p className='p-4'>Loading ...</p>,
});

const categories = {
	doubtSolving: "Doubt Solving",
	explanation: "Explanation",
	trainerCamera: "Camera",
	overall: "Overall",
};

const Chart = ({ label, value }) => {
	const [chart, setChart] = useState({
		type: "radialBar",
		series: [value ? value.toFixed(2) / 0.1 : 0],
		options: {
			chart: {
				type: "radialBar",
				toolbar: {
					show: false,
				},
				zoom: {
					enabled: false,
				},
			},
			plotOptions: {
				radialBar: {
					startAngle: -135,
					endAngle: 225,
					hollow: {
						margin: 0,
						size: "70%",
						background: "#fff",
						image: undefined,
						imageOffsetX: 0,
						imageOffsetY: 0,
						position: "front",
						dropShadow: {
							enabled: true,
							top: 3,
							left: 0,
							blur: 4,
							opacity: 0.24,
						},
					},
					track: {
						background: "#fff",
						strokeWidth: "67%",
						margin: 0, // margin is in pixels
						dropShadow: {
							enabled: true,
							top: -3,
							left: 0,
							blur: 4,
							opacity: 0.35,
						},
					},

					dataLabels: {
						show: true,
						name: {
							offsetY: -10,
							show: true,
							color: "#888",
							fontSize: "17px",
						},
						value: {
							formatter: function (val) {
								return `${parseInt(val * 0.1)}/10`;
							},
							color: "#111",
							fontSize: "36px",
							show: true,
						},
					},
				},
			},
			colors: ["#D9534F"],
			fill: {
				type: "gradient",
				gradient: {
					shade: "dark",
					type: "horizontal",
					shadeIntensity: 0.5,
					gradientToColors: ["#ff6600"],
					// inverseColors: true,
					opacityFrom: 1,
					opacityTo: 1,
					stops: [0, 100],
				},
			},
			stroke: {
				lineCap: "round",
			},
			labels: [label],
		},
	});

	useEffect(
		() =>
			setChart((prev) => ({
				...prev,
				series: [value ? value.toFixed(2) / 0.1 : 0],
			})),
		[value]
	);

	return (
		<>
			<ApexChartNoSSRWrapper {...chart} />
		</>
	);
};

const FeedbackList = () => {
	const feedbackList = useRecoilValue(feedbackListState);

	return (
		<>
			{feedbackList.average && feedbackList.feedbacks ? (
				<div className='w-full px-2 space-y-4'>
					{feedbackList.average && (
						<div className='flex flex-wrap'>
							<div className='w-full md:w-1/2 lg:w-1/4'>
								<Chart
									label='Doubt Solving'
									value={feedbackList.average.doubtSolving}
								/>
							</div>
							<div className='w-full md:w-1/2 lg:w-1/4'>
								<Chart
									label='Explanation'
									value={feedbackList.average.explanation}
								/>
							</div>
							<div className='w-full md:w-1/2 lg:w-1/4'>
								<Chart
									label='Camera'
									value={feedbackList.average.trainerCamera}
								/>
							</div>
							<div className='w-full md:w-1/2 lg:w-1/4'>
								<Chart
									label='Overall'
									value={feedbackList.average.overall}
								/>
							</div>
						</div>
					)}
					<hr />
					<div className='overflow-y-scroll max-h-[500px] scrollbar-hide'>
						<table className='divide-y divide-gray-200 table-auto relative h-full w-full'>
							<thead className='bg-gray-50 sticky top-0 border-b-[1px] border-b-gray-200'>
								<tr>
									<th
										scope='col'
										className='px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider'
									>
										Sr. no.
									</th>
									<th
										scope='col'
										className='px-6 pr-3 pl-20 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
									>
										Name
									</th>
									<th
										scope='col'
										className='px-6 text-center text-xs font-medium text-gray-500 uppercase tracking-wider'
									>
										Submitted At
									</th>
									<th
										scope='col'
										className='px-6 text-center text-xs font-medium text-gray-500 uppercase tracking-wider'
									>
										Doubt Solving
									</th>
									<th
										scope='col'
										className='px-6 text-center text-xs font-medium text-gray-500 uppercase tracking-wider'
									>
										Explanation
									</th>
									<th
										scope='col'
										className='px-6 text-center text-xs font-medium text-gray-500 uppercase tracking-wider'
									>
										Camera
									</th>
									<th
										scope='col'
										className='px-6 text-center text-xs font-medium text-gray-500 uppercase tracking-wider'
									>
										Overall
									</th>
								</tr>
							</thead>
							<tbody className='bg-white divide-y divide-gray-200'>
								{feedbackList?.feedbacks?.map(
									(
										{
											user,
											doubtSolving,
											explanation,
											overall,
											trainerCamera,
											createdAt,
										},
										idx
									) => (
										<tr key={`${user.uid}_${createdAt}`}>
											<td className='px-6 py-2 whitespace-nowrap text-center'>
												<div className='text-sm text-gray-900'>
													{idx + 1}
												</div>
											</td>
											<td className='px-6 py-2 whitespace-nowrap'>
												<div className='flex items-center'>
													<div className='flex-shrink-0 h-8 w-8 flex justify-center items-center'>
														<img
															className='h-8 w-8 rounded-full'
															src={
																user.profileImageUrl ||
																`https://avatars.dicebear.com/api/initials/${user.name}.svg`
															}
															alt={user.username}
														/>
													</div>
													<div className='ml-4'>
														<a
															href={`https://letsupgrade.in/user/${user.username}`}
															target='_blank'
															className='text-black hover:text-lu-500'
														>
															{user.name}
														</a>
													</div>
												</div>
											</td>
											<td className='px-6 py-2 whitespace-nowrap text-center'>
												<div className='text-sm text-gray-900 cursor-pointer'>
													{
														formatDate(createdAt)
															.dateTime
													}
												</div>
											</td>
											<td className='px-6 py-2 whitespace-nowrap text-center'>
												<div className='text-sm text-gray-900 cursor-pointer'>
													{doubtSolving}
												</div>
											</td>
											<td className='px-6 py-2 whitespace-nowrap text-center'>
												<div className='text-sm text-gray-900 cursor-pointer'>
													{explanation}
												</div>
											</td>
											<td className='px-6 py-2 whitespace-nowrap text-center'>
												<div className='text-sm text-gray-900 cursor-pointer'>
													{trainerCamera}
												</div>
											</td>
											<td className='px-6 py-2 whitespace-nowrap text-center'>
												<div className='text-sm text-gray-900 cursor-pointer'>
													{overall}
												</div>
											</td>
										</tr>
									)
								)}
							</tbody>
						</table>
					</div>
				</div>
			) : (
				<div className='flex flex-col justify-center items-center space-x-1 text-black h-full select-none space-y-8'>
					<img
						src='/noResource.png'
						alt="No Feedback's Listed"
						width='200px'
					/>
					<span className='text-gray-500'>No Feedback's Listed</span>
				</div>
			)}
		</>
	);
};

export default FeedbackList;
