import { atom, selector } from "recoil";

export const statsState = atom({
	key: "statsState",
	default: {
		assignmentQuestion: null,
		agendaList: [],
		participantList: { participants: [], total:0, page: 0 },
		doubtList: { doubts: [], total: 0, solved: 0, page: 0 },
		resourceList: { resources: [], total: 0, page: 0 },
		feedbackList: { average: {}, feedbacks: [] },
		avgRating: 0,
	},
});

export const assignmentQuestionState = selector({
	key: "assignmentQuestionState",
	get: ({ get }) => {
		return get(statsState).assignmentQuestion;
	},
});

export const agendaListState = selector({
	key: "agendaListState",
	get: ({ get }) => {
		return get(statsState).agendaList;
	},
});

export const participantListState = selector({
	key: "participantListState",
	get: ({ get }) => {
		return get(statsState).participantList;
	},
});

export const doubtListState = selector({
	key: "doubtListState",
	get: ({ get }) => {
		return get(statsState).doubtList;
	},
});

export const resourceListState = selector({
	key: "resourceListState",
	get: ({ get }) => {
		return get(statsState).resourceList;
	},
});

export const feedbackListState = selector({
	key: "feedbackListState",
	get: ({ get }) => {
		return get(statsState).feedbackList;
	},
});
