import { format } from "date-fns"
import { useEffect, useState } from "react"

const LiveDateTime = () => {
	const [dateTime, setDateTime] = useState(new Date())

	useEffect(() => {
		const dateTimeInterval = setInterval(() => {
			setDateTime(new Date())
		}, 1000)
		return () => clearInterval(dateTimeInterval)
	})

	return (
		<>
			<section aria-labelledby="currentDateTime-title">
				<div className="overflow-hidden rounded-lg bg-white shadow">
					<div className="p-6">
						<h2
							className="text-base font-medium text-gray-900"
							id="currentDateTime-title"
						>
							{format(dateTime, "LLL do yyyy, hh:mm:ss b")}
						</h2>
					</div>
				</div>
			</section>
		</>
	)
}

export default LiveDateTime
