import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/outline"
import React from "react"

const Pagination = ({ setPageNo, pageNo, perPage, total }) => {
	return (
		<>
			<div className="flex items-center justify-between">
				<button
					className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-0 disabled:cursor-not-allowed disabled:bg-gray-100 disabled:opacity-50"
					onClick={() => setPageNo(pageNo - 1)}
					disabled={pageNo === 1}
				>
					<p className="invisible absolute -right-12 hidden rounded-3xl bg-black py-1 px-2 text-xs text-white group-hover:visible md:block">
						Prev
					</p>
					<ArrowLeftIcon className="h-4 w-4 text-black" />
				</button>
				<span className="flex cursor-pointer items-center space-x-1 text-sm text-gray-700">
					<span className="hidden md:block">Showing</span>
					<span className="font-medium">
						{pageNo * perPage - perPage + 1}
					</span>
					<span>to</span>
					<span className="font-medium">
						{pageNo * perPage > total ? total : pageNo * perPage}
					</span>
					<span>of</span>
					<span className="font-medium">{total}</span>
					<span>results</span>
				</span>
				<button
					className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-0 disabled:cursor-not-allowed disabled:bg-gray-100 disabled:opacity-50"
					onClick={() => setPageNo(pageNo + 1)}
					disabled={pageNo === Math.ceil(total / perPage)}
				>
					<p className="invisible absolute -left-12 rounded-3xl bg-black py-1 px-2 text-xs text-white group-hover:visible">
						Next
					</p>
					<ArrowRightIcon className="h-4 w-4 text-black" />
				</button>
			</div>
		</>
	)
}

export default Pagination
